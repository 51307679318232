import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@/contexts/ThemeContext";
import { AuthProvider } from "@/contexts/AuthContext";
import App from "./App";
import "./styles/globals.css";
import { NagishliWidget } from "./NagishliWidget";

// Types
declare global {
  interface Window {
    adsbygoogle: any[];
    hasPageLevelAds?: boolean;
  }
}

// Constants
// const ADSENSE_CONFIG = {
//   CLIENT_ID: "ca-pub-7232224177198894",
//   SLOT_ID: "9395499128",
// } as const;

// Query Client Configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

// AdSense Component
// function AdComponent() {
//   const adRef = useRef(null);

//   useEffect(() => {
//     try {
//       if (typeof window.adsbygoogle === "undefined") {
//         window.adsbygoogle = [];
//       }

//       if (adRef.current) {
//         (window.adsbygoogle = window.adsbygoogle || []).push({});
//       }
//     } catch (error) {
//       console.error("AdSense initialization error:", error);
//     }
//   }, []);

//   return (
//     <div className="w-full my-5 bg-gray-100 min-h-[280px] flex items-center justify-center">
//       <ins
//         ref={adRef}
//         className="adsbygoogle"
//         style={{
//           display: "block",
//           width: "100%",
//           minHeight: "280px",
//         }}
//         data-ad-client={ADSENSE_CONFIG.CLIENT_ID}
//         data-ad-slot={ADSENSE_CONFIG.SLOT_ID}
//         data-ad-format="auto"
//         data-full-width-responsive="true"
//       />
//     </div>
//   );
// }

// Accessible Navigation - Fixed for accessibility
function NavigationPlaceholder() {
  return (
    <select className="navigation-select" aria-label="ניווט מהיר">
      <option
        value=""
        disabled
        data-nagishli-lang-phrase="navigation_quick_placeholder"
        defaultValue="בחרו מיקום"
      >
        בחרו מיקום
      </option>
    </select>
  );
}

// Root Component
function Root() {
  return (
    <div dir="rtl">
      <NagishliWidget />
      <NavigationPlaceholder />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <AuthProvider>
              {/* <AdComponent /> */}
              <App />
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </div>
  );
}

// Set document properties
document.documentElement.lang = "he";
document.documentElement.dir = "rtl";

// Initialize the application
const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Root element not found");

// Create and render the root
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
