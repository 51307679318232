import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectTrigger,
  SelectValue,
  SelectItem,
} from "@/components/ui/select";
import { Label } from "@/components/common/Label";
import { ValidateRaffle } from "./validate";
import type { NewRaffle, FormErrors } from "@/types/raffle";
import { DEFAULT_NEW_RAFFLE, RAFFLE_TYPES } from "@/constants/raffle";

interface RaffleFormProps {
  initialData?: NewRaffle;
  onSubmit: (data: NewRaffle) => Promise<void>;
  isLoading?: boolean;
}

export function RaffleForm({
  initialData,
  onSubmit,
  isLoading,
}: RaffleFormProps) {
  const [formData, setFormData] = useState<NewRaffle>(
    initialData || DEFAULT_NEW_RAFFLE
  );
  const [errors, setErrors] = useState<FormErrors>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = ValidateRaffle(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    await onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 rtl p-4">
      {/* כותרת */}
      <div className="space-y-2">
        <Label>כותרת</Label>
        <Input
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          placeholder="הזן כותרת להגרלה"
          className={errors.title ? "border-red-500" : ""}
        />
        {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}
      </div>

      {/* תיאור */}
      <div className="space-y-2">
        <Label>תיאור</Label>
        <Input
          value={formData.description}
          onChange={(e) =>
            setFormData({ ...formData, description: e.target.value })
          }
          placeholder="הזן תיאור להגרלה"
          className={errors.description ? "border-red-500" : ""}
        />
        {errors.description && (
          <p className="text-red-500 text-sm">{errors.description}</p>
        )}
      </div>

      {/* סוג הגרלה */}
      <div className="space-y-2">
        <Label>סוג הגרלה</Label>
        <Select
          value={formData.type}
          onValueChange={(value) =>
            setFormData({ ...formData, type: value as any })
          }
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="בחר סוג הגרלה" />
          </SelectTrigger>
          <SelectContent>
            {RAFFLE_TYPES.map((type) => (
              <SelectItem key={type.value} value={type.value}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* תאריך */}
      <div className="space-y-2">
        <Label>תאריך</Label>
        <Input
          type="date"
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          className={errors.date ? "border-red-500" : ""}
        />
        {errors.date && <p className="text-red-500 text-sm">{errors.date}</p>}
      </div>

      {/* שעת הגרלה */}
      <div className="space-y-2">
        <Label>שעת הגרלה</Label>
        <Input
          type="time"
          value={formData.scheduleTime}
          onChange={(e) =>
            setFormData({ ...formData, scheduleTime: e.target.value })
          }
          className={errors.scheduleTime ? "border-red-500" : ""}
        />
        {errors.scheduleTime && (
          <p className="text-red-500 text-sm">{errors.scheduleTime}</p>
        )}
      </div>

      {/* מחיר בנקודות */}
      <div className="space-y-2">
        <Label>מחיר בנקודות/השתתפות</Label>
        <Input
          type="number"
          value={formData.pointsCost}
          onChange={(e) =>
            setFormData({ ...formData, pointsCost: Number(e.target.value) })
          }
          min="0"
          className={errors.pointsCost ? "border-red-500" : ""}
        />
        {errors.pointsCost && (
          <p className="text-red-500 text-sm">{errors.pointsCost}</p>
        )}
      </div>

      {/* קודי זכייה */}
      <div className="space-y-2">
        <div className="flex justify-between items-center">
          <Label>קודי זכייה</Label>
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() =>
              setFormData({
                ...formData,
                prizeCodes: [...formData.prizeCodes, ""],
              })
            }
          >
            הוסף קוד +
          </Button>
        </div>
        <div className="space-y-2">
          {formData.prizeCodes.map((code, index) => (
            <Input
              key={index}
              value={code}
              onChange={(e) => {
                const newCodes = [...formData.prizeCodes];
                newCodes[index] = e.target.value;
                setFormData({ ...formData, prizeCodes: newCodes });
              }}
              placeholder={`קוד זכייה ${index + 1}`}
              className={errors.prizeCodes?.[index] ? "border-red-500" : ""}
            />
          ))}
        </div>
      </div>

      {/* תמונה */}
      <div className="space-y-2">
        <Label>תמונה</Label>
        <Input
          value={formData.imageUrl}
          onChange={(e) =>
            setFormData({ ...formData, imageUrl: e.target.value })
          }
          placeholder="הזן קישור לתמונה"
          className={errors.imageUrl ? "border-red-500" : ""}
        />
        {errors.imageUrl && (
          <p className="text-red-500 text-sm">{errors.imageUrl}</p>
        )}
      </div>

      <Button type="submit" className="w-full" disabled={isLoading}>
        {isLoading ? "שומר..." : "שמור"}
      </Button>
    </form>
  );
}
