import AppRoutes from "@/routes";
import { ToastProvider } from "./components/common/Toast";

function App() {
  return (
    <ToastProvider>
      <AppRoutes />
    </ToastProvider>
  );
}

export default App;
