import React from 'react';
import { MessageSquare, ThumbsUp, Eye, BookOpen } from 'lucide-react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';

interface ArticleCardProps {
  article: {
    id: number;
    title: string;
    content: string;
    author: string;
    date: string;
    views: number;
    likes: number;
    comments: number;
    category: string;
  };
  featured?: boolean;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/articles/${article.id}`);
  };

  return (
    <Card 
      className="glass text-white border-white/20 hover:scale-105 transition-all duration-300 cursor-pointer"
      onClick={handleClick}
    >
      <CardHeader className="flex flex-col space-y-2">
        <div className="flex justify-between items-start">
          <h3 className="text-xl font-bold">{article.title}</h3>
          <span className="text-sm px-3 py-1 bg-white/10 rounded-full">
            {article.category}
          </span>
        </div>
      </CardHeader>
      <CardContent>
        <div className="whitespace-pre-wrap mb-4 text-white/80 line-clamp-3">
          {article.content}
        </div>
        <div className="flex justify-between items-center text-sm text-white/60">
          <div className="flex items-center space-x-4 space-x-reverse">
            <div className="flex items-center">
              <Eye className="w-4 h-4 ml-1" />
              {article.views.toLocaleString()}
            </div>
            <div className="flex items-center">
              <ThumbsUp className="w-4 h-4 ml-1" />
              {article.likes.toLocaleString()}
            </div>
            <div className="flex items-center">
              <MessageSquare className="w-4 h-4 ml-1" />
              {article.comments.toLocaleString()}
            </div>
          </div>
          <div className="flex items-center">
            <BookOpen className="w-4 h-4 ml-1" />
            {article.date}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ArticleCard;