import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { db } from "@/lib/firebase";
import SuccessDialog from "@/components/missions/dialog/SuccessDialog";

import {
  doc,
  updateDoc,
  getDoc,
  collection,
  addDoc,
  Timestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Upload,
  Youtube,
  Share2,
  Code,
  AlertTriangle,
  Clock,
  CalendarDays,
  Calendar,
  Star,
  ListFilter,
  Loader2,
} from "lucide-react";
import { Progress } from "@/components/ui/progress";
import {
  Mission,
  MissionType,
  MissionStatus,
  isVideoMission,
  isUploadMission,
  isSocialMission,
  isCodeMission,
  MissionCompletionData,
  MissionFrequency,
} from "@/types/mission";
import MissionDialog from "@/components/missions/dialog/MissionDialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/common/Dialog";
import { toast } from "@/components/common/Use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import React from "react";

// Types
type IconMissionType = Extract<
  MissionType,
  "video" | "upload" | "social" | "code"
>;
type SortFrequency = "all" | MissionFrequency;

interface UploadWarningDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isUploading: boolean; // חדש
  uploadProgress: number; // חדש
}
// Constants
const MISSION_ICONS: Record<IconMissionType, JSX.Element> = {
  video: <Youtube className="w-4 h-4 ml-2" />,
  upload: <Upload className="w-4 h-4 ml-2" />,
  social: <Share2 className="w-4 h-4 ml-2" />,
  code: <Code className="w-4 h-4 ml-2" />,
};

const STATUS_COLORS: Record<MissionStatus, string> = {
  active: "text-green-600",
  inactive: "text-gray-400",
};

const FREQUENCY_LABELS = {
  all: "כל המשימות",
  daily: "משימות יומיות",
  weekly: "משימות שבועיות",
  monthly: "משימות חודשיות",
  once: "משימות חד פעמיות",
} as const;

// Warning Dialog Component
const UploadWarningDialog: React.FC<UploadWarningDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isUploading,
  uploadProgress,
}) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle className="flex items-center gap-2 text-red-600">
          <AlertTriangle className="w-5 h-5" />
          אזהרה חשובה
        </DialogTitle>
      </DialogHeader>

      <div className="space-y-4">
        <div className="text-center p-4 bg-red-50 rounded-lg border border-red-200">
          <h3 className="font-bold text-red-600 mb-2">⚠️ שימו לב</h3>
          <p className="text-gray-700">
            חל איסור מוחלט על רמאות או שימוש לרעה במערכת. העלאת צילומי מסך
            מזויפים או לא רלוונטיים תגרור חסימה מיידית וביטול כל הנקודות שנצברו.
          </p>
        </div>

        <div className="text-sm text-gray-500">
          בלחיצה על "אני מאשר" אני מצהיר שהתמונה שאני עומד להעלות היא אותנטית
          ועומדת בדרישות המשימה.
        </div>

        {isUploading && (
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm text-gray-600">
              <span>העלאת תמונה...</span>
              <span>{uploadProgress}%</span>
            </div>
            <Progress value={uploadProgress} className="h-2" />
          </div>
        )}
      </div>

      <div className="flex justify-end gap-2">
        <Button variant="outline" onClick={onClose} disabled={isUploading}>
          ביטול
        </Button>
        <Button
          onClick={onConfirm}
          disabled={isUploading}
          className="bg-red-600 hover:bg-red-700 text-white min-w-[120px]"
        >
          {isUploading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              העלאת תמונה...
            </>
          ) : (
            "אני מאשר"
          )}
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);
const MissionsPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [missions, setMissions] = useState<Mission[]>([]);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [userPoints, setUserPoints] = useState(0);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [sortFrequency, setSortFrequency] = useState<SortFrequency>("all");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // Utility Functions
  const getMissionIcon = (type: MissionType): JSX.Element => {
    return MISSION_ICONS[type] || <Code className="w-4 h-4 ml-2" />;
  };

  const getStatusColor = (status: MissionStatus): string => {
    return STATUS_COLORS[status] || "text-gray-500";
  };

  const getFrequencyIcon = (frequency: MissionFrequency): JSX.Element => {
    switch (frequency) {
      case "daily":
        return <Clock className="w-3 h-3 ml-1" />;
      case "weekly":
        return <CalendarDays className="w-3 h-3 ml-1" />;
      case "monthly":
        return <Calendar className="w-3 h-3 ml-1" />;
      case "once":
        return <Star className="w-3 h-3 ml-1" />;
      default:
        return <></>;
    }
  };

  const getFrequencyLabel = (frequency: MissionFrequency): string => {
    switch (frequency) {
      case "daily":
        return "משימה יומית";
      case "weekly":
        return "משימה שבועית";
      case "monthly":
        return "משימה חודשית";
      case "once":
        return "משימה חד פעמית";
      default:
        return "";
    }
  };

  const formatNextAvailable = (timestamp: Timestamp): string => {
    return new Date(timestamp.seconds * 1000).toLocaleDateString("he-IL");
  };
  const getCooldownProgress = (
    nextAvailable: Timestamp,
    frequency: MissionFrequency
  ): number => {
    const now = new Date().getTime();
    const availableDate = nextAvailable.toDate().getTime();
    const diff = availableDate - now;

    let totalDuration: number;
    switch (frequency) {
      case "daily":
        totalDuration = 24 * 60 * 60 * 1000; // 24 hours
        break;
      case "weekly":
        totalDuration = 7 * 24 * 60 * 60 * 1000; // 7 days
        break;
      case "monthly":
        totalDuration = 30 * 24 * 60 * 60 * 1000; // 30 days
        break;
      case "once":
      default:
        return 0;
    }

    const progress = ((totalDuration - diff) / totalDuration) * 100;
    return Math.max(0, Math.min(100, progress));
  };

  const getMissionButtonContent = (mission: Mission): JSX.Element => {
    return (
      <>
        {getMissionIcon(mission.type)}
        התחל משימה
      </>
    );
  };

  // Data Functions
  const updatePoints = async (points: number) => {
    if (!currentUser?.id) return;

    try {
      const userRef = doc(db, "users", currentUser.id);

      // קודם מביאים את הנקודות הנוכחיות
      const userDoc = await getDoc(userRef);
      const currentPoints = userDoc.data()?.points || 0;

      // מעדכנים עם הנקודות החדשות
      await updateDoc(userRef, {
        points: currentPoints + points,
      });

      // מעדכנים את ה-state
      setUserPoints(currentPoints + points);
    } catch (error) {
      console.error("Error updating points:", error);
      setError("שגיאה בעדכון הנקודות");
    }
  };
  const calculateNextAvailable = (frequency: MissionFrequency) => {
    const now = new Date();
    switch (frequency) {
      case "daily":
        const tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        return Timestamp.fromDate(tomorrow);
      case "weekly":
        const nextWeek = new Date(now);
        nextWeek.setDate(nextWeek.getDate() + 7);
        nextWeek.setHours(0, 0, 0, 0);
        return Timestamp.fromDate(nextWeek);
      case "monthly":
        const nextMonth = new Date(now);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setHours(0, 0, 0, 0);
        return Timestamp.fromDate(nextMonth);
      case "once":
        return null;
      default:
        return null;
    }
  };

  const submitMission = async (
    mission: Mission,
    additionalData: Record<string, any> = {}
  ) => {
    if (!currentUser?.id) return;

    const nextAvailable = calculateNextAvailable(mission.frequency);

    try {
      // הוספת ה-submission
      await addDoc(collection(db, "mission_submissions"), {
        userId: currentUser.id,
        missionId: mission.id,
        type: mission.type,
        status: "active",
        timestamp: Timestamp.now(),
        nextAvailableAt: nextAvailable,
        ...additionalData,
      });

      // הוספת פעילות
      await addDoc(collection(db, "user_activities"), {
        userId: currentUser.id,
        type: "participation",
        title: `השלמת משימה: ${mission.title}`,
        description: `השלמת משימה וקיבלת ${mission.points} נקודות`,
        timestamp: Timestamp.now(),
      });

      // עדכון נקודות
      await updatePoints(mission.points);

      toast({
        title: "המשימה הושלמה!",
        description: `קיבלת ${mission.points} נקודות`,
      });

      await fetchMissions();
    } catch (error) {
      console.error("Error submitting mission:", error);
      throw error;
    }
  };
  // Upload Functions
  const handleConfirmUpload = async () => {
    if (
      !currentUser?.id ||
      !selectedMission ||
      !isUploadMission(selectedMission) ||
      !selectedImage
    )
      return;

    try {
      setIsUploading(true);
      setUploadProgress(0);

      // נדמה את תהליך ההעלאה
      let progress = 0;
      await new Promise<void>((resolve) => {
        const fakeUploadInterval = setInterval(() => {
          progress += 5;
          setUploadProgress(progress);
          if (progress >= 100) {
            clearInterval(fakeUploadInterval);
            resolve();
          }
        }, 200);
      });

      // נמתין עוד רגע אחרי שמגיע ל-100% לפני שנמשיך
      await new Promise((resolve) => setTimeout(resolve, 500));

      const fakeDownloadURL = `https://fake-storage.example.com/mission-uploads/${
        currentUser.id
      }/${Date.now()}_${selectedImage.name}`;
      await submitMission(selectedMission, { imageUrl: fakeDownloadURL });

      setSelectedImage(null);
      setShowWarningDialog(false);
      setShowSuccessDialog(true);
      await fetchMissions();
    } catch (error) {
      console.error("Error in handleConfirmUpload:", error);
      setError("שגיאה בהעלאת התמונה");
      toast({
        title: "שגיאה",
        description: "אירעה שגיאה בהעלאת התמונה",
        variant: "destructive",
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };
  // תיקון handleMissionComplete
  const handleMissionComplete = async (
    mission: Mission,
    data: MissionCompletionData
  ) => {
    if (!currentUser?.id) return;

    try {
      switch (mission.type) {
        case "video":
          if (isVideoMission(mission) && data.watchTime) {
            await submitMission(mission, {
              watchedTime: data.watchTime,
            });
          }
          break;

        case "upload":
          if (isUploadMission(mission) && data.url) {
            await submitMission(mission, {
              imageUrl: data.url,
              status: "active", // משימת העלאה רגילה
            });
            setShowWarningDialog(true); // מציג את דיאלוג האזהרה
          }
          break;

        case "social":
          if (isSocialMission(mission)) {
            const text = encodeURIComponent(
              mission.sharingText || "בואו להרוויח פרסים!"
            );
            const url = encodeURIComponent(window.location.origin);
            window.open(`https://wa.me/?text=${text}%20${url}`, "_blank");

            await submitMission(mission, {
              platform: "whatsapp",
              sharedUrl: window.location.origin,
              sharedText: mission.sharingText,
              status: "active",
            });
          }
          break;

        case "code":
          if (isCodeMission(mission) && data.code) {
            if (mission.validCodes.includes(data.code)) {
              await submitMission(mission, {
                code: data.code,
                status: "active",
              });
            } else {
              setError("קוד לא תקין");
              return;
            }
          }
          break;
      }

      setSelectedMission(null);
      await fetchMissions();
    } catch (error) {
      console.error("Error completing mission:", error);
      setError("שגיאה בהשלמת המשימה");
    }
  };

  // Fetch Functions
  const fetchMissions = async () => {
    if (!currentUser?.id) return;

    setLoading(true);
    try {
      const missionsRef = collection(db, "missions");
      const q = query(missionsRef, where("status", "==", "active"));
      const querySnapshot = await getDocs(q);

      const missionsData: Mission[] = [];
      for (const doc of querySnapshot.docs) {
        const missionData = { ...doc.data(), id: doc.id } as Mission;

        // בדיקת submissions קיימים
        const submissionsRef = collection(db, "mission_submissions");
        const submissionsQuery = query(
          submissionsRef,
          where("userId", "==", currentUser.id),
          where("missionId", "==", doc.id)
        );
        const submissionsSnapshot = await getDocs(submissionsQuery);

        // אם יש submission
        if (!submissionsSnapshot.empty) {
          const submission = submissionsSnapshot.docs[0].data();

          // אם זו משימה חד פעמית והיא כבר בוצעה, נדלג עליה
          if (missionData.frequency === "once") {
            continue;
          }

          // אם יש תאריך זמינות הבא
          if (submission.nextAvailableAt) {
            missionData.nextAvailableAt = submission.nextAvailableAt;
            const now = new Date();
            const nextAvailable = submission.nextAvailableAt.toDate();

            // אם עוד לא הגיע הזמן, נדלג
            if (now < nextAvailable) {
              continue;
            }
          }
        }

        missionsData.push(missionData);
      }

      setMissions(missionsData);
    } catch (err) {
      console.error("Error fetching missions:", err);
      setError("שגיאה בטעינת המשימות");
    } finally {
      setLoading(false);
    }
  };

  const fetchUserPoints = async () => {
    if (!currentUser?.id) return;

    try {
      const userDoc = await getDoc(doc(db, "users", currentUser.id));
      if (userDoc.exists()) {
        setUserPoints(userDoc.data().points || 0);
      }
    } catch (error) {
      console.error("Error fetching points:", error);
      setError("שגיאה בטעינת הנקודות");
    }
  };

  // Click Handler
  const handleMissionClick = (mission: Mission) => {
    if (!currentUser) {
      navigate("/login");
      return;
    }
    setSelectedMission(mission);
  };

  // Filter missions based on frequency
  const filteredMissions = React.useMemo(() => {
    if (sortFrequency === "all") {
      return missions;
    }
    return missions.filter((mission) => mission.frequency === sortFrequency);
  }, [missions, sortFrequency]);

  // Lifecycle
  useEffect(() => {
    if (currentUser) {
      fetchMissions();
      fetchUserPoints();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4" dir="rtl">
      <div className="mb-6 flex flex-col sm:flex-row items-center justify-between">
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl font-bold mb-2">משימות</h1>
          <p className="text-gray-600">השלם משימות וצבור נקודות!</p>
        </div>

        <div className="flex items-center space-x-4 space-x-reverse">
          <Select
            value={sortFrequency}
            onValueChange={(value: SortFrequency) => setSortFrequency(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="סנן לפי תדירות" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(FREQUENCY_LABELS).map(([key, label]) => (
                <SelectItem key={key} value={key}>
                  <div className="flex items-center">
                    {key === "daily" && <Clock className="w-4 h-4 ml-2" />}
                    {key === "weekly" && (
                      <CalendarDays className="w-4 h-4 ml-2" />
                    )}
                    {key === "monthly" && <Calendar className="w-4 h-4 ml-2" />}
                    {key === "once" && <Star className="w-4 h-4 ml-2" />}
                    {key === "all" && <ListFilter className="w-4 h-4 ml-2" />}
                    {label}
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="text-center">
          <p className="text-lg font-bold">{userPoints}</p>
          <p className="text-sm text-gray-600">נקודות</p>
        </div>
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {filteredMissions.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-xl text-gray-500">אין משימות זמינות בקטגוריה זו</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredMissions.map((mission) => (
            <Card key={mission.id} className="overflow-hidden">
              <CardHeader className="p-0">
                {mission.imageUrl && (
                  <div className="w-full aspect-video">
                    <img
                      src={mission.imageUrl}
                      alt={mission.title}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = "/placeholder-image.jpg";
                      }}
                    />
                  </div>
                )}
                <div className="p-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-lg font-semibold">{mission.title}</h3>
                      <div className="flex items-center mt-1 text-sm text-gray-500">
                        {getFrequencyIcon(mission.frequency)}
                        {getFrequencyLabel(mission.frequency)}
                      </div>
                    </div>
                    <span
                      className={`flex items-center ${getStatusColor(
                        mission.status
                      )}`}
                    >
                      {mission.points} נקודות
                    </span>
                  </div>
                </div>
              </CardHeader>

              <CardContent>
                <p className="text-gray-600 mb-4">{mission.description}</p>

                {mission.nextAvailableAt && (
                  <div className="mb-4">
                    <p className="text-sm text-gray-500">
                      זמין שוב בתאריך:{" "}
                      {formatNextAvailable(mission.nextAvailableAt)}
                    </p>
                    <Progress
                      value={getCooldownProgress(
                        mission.nextAvailableAt,
                        mission.frequency
                      )}
                      className="mt-2"
                    />
                  </div>
                )}

                <Button
                  onClick={() => handleMissionClick(mission)}
                  disabled={
                    mission.status === "inactive" ||
                    (mission.nextAvailableAt &&
                      new Date() < mission.nextAvailableAt.toDate())
                  }
                  className="w-full"
                >
                  {getMissionButtonContent(mission)}
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {selectedMission && (
        <MissionDialog
          isOpen={!!selectedMission}
          onClose={() => setSelectedMission(null)}
          mission={selectedMission}
          onComplete={handleMissionComplete}
          onImageSelect={(file: File | null) => {
            setSelectedImage(file);
            setShowWarningDialog(true);
          }}
        />
      )}
      {selectedMission && (
        <SuccessDialog
          isOpen={showSuccessDialog}
          onClose={() => setShowSuccessDialog(false)}
          points={selectedMission.points}
        />
      )}

      <UploadWarningDialog
        isOpen={showWarningDialog}
        onClose={() => {
          if (!isUploading) {
            console.log("Warning dialog closed");
            setShowWarningDialog(false);
          }
        }}
        onConfirm={() => {
          console.log("Upload confirmed");
          handleConfirmUpload();
        }}
        isUploading={isUploading}
        uploadProgress={uploadProgress}
      />
    </div>
  );
};

export default MissionsPage;
