export const articles = [
  {
    id: 1,
    title: "מדריך למתחילים: איך לשרוד את 10 הדקות הראשונות בפורטנייט?",
    content: `במדריך זה נלמד את הטכניקות החיוניות להישרדות בדקות הראשונות של המשחק.
  
        בחירת נקודת נחיתה:
        • בחרו מקומות פחות פופולריים בהתחלה
        • שימו לב למסלול האוטובוס ובחרו מיקום במרחק בינוני
        • וודאו שיש מספיק מבנים באזור לאיסוף ציוד
  
        ציוד התחלתי חיוני:
        1. נשק לטווח קצר (שוטגאן)
        2. נשק לטווח בינוני (רובה סער)
        3. שתייה ותחבושות
        4. חומרי בנייה`,
    author: "NinjaFortnite",
    date: "08.01.2024",
    views: 1542,
    likes: 89,
    comments: 23,
    category: "מדריכים",
  },
  {
    id: 2,
    title: "טיפים מקצועיים לשיפור הכוונת",
    content: `שיפור הכוונת הוא אחד האלמנטים החשובים ביותר במשחק. הנה כמה טיפים שיעזרו לכם:
  
        הגדרות בסיסיות:
        • התאימו את רגישות העכבר
        • כוונו את ה-DPI
        • שנו את הגדרות התצוגה למקסימום FPS
  
        תרגילים יומיים:
        1. אימון במצב Creative
        2. תרגול בניית מהירה
        3. משחק במצב Team Rumble`,
    author: "AimPro",
    date: "07.01.2024",
    views: 1203,
    likes: 67,
    comments: 15,
    category: "טיפים",
  },
  {
    id: 3,
    title: "כל מה שצריך לדעת על העונה החדשה",
    content: `העונה החדשה הגיעה עם המון שינויים מרעננים. הנה הפרטים החשובים:
  
        שינויים במפה:
        • אזורים חדשים
        • שינויים בנקודות עניין קיימות
        • מיקומי לוט חדשים
  
        נשקים חדשים:
        1. רובה סער משופר
        2. אקדח חדש
        3. נשק מיוחד לעונה`,
    author: "FortniteExpert",
    date: "06.01.2024",
    views: 2567,
    likes: 178,
    comments: 45,
    category: "חדשות",
  },
];

export type Article = {
  id: number;
  title: string;
  content: string;
  author: string;
  date: string;
  views: number;
  likes: number;
  comments: number;
  category: string;
};
