import type { NewRaffle, FormErrors } from "@/types/raffle";

export function ValidateRaffle(data: NewRaffle): FormErrors {
  const errors: FormErrors = {};

  if (!data.title.trim()) {
    errors.title = "שדה חובה";
  } else if (data.title.length < 3) {
    errors.title = "הכותרת חייבת להכיל לפחות 3 תווים";
  }

  if (!data.description.trim()) {
    errors.description = "שדה חובה";
  } else if (data.description.length < 10) {
    errors.description = "התיאור חייב להכיל לפחות 10 תווים";
  }

  // ולידציות נוספות בהתאם לסוג ההגרלה
  switch (data.type) {
    case "oneTime":
      if (!data.date) {
        errors.date = "שדה חובה";
      }
      break;
    case "weekly":
      if (!data.scheduleDay) {
        errors.scheduleDay = "נא לבחור יום";
      }
      break;
  }

  return errors;
}
