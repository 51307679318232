import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { formatDistance } from "date-fns";
import { he } from "date-fns/locale";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Clock,
  Users,
  Calendar,
  Ticket,
  Timer,
  CircleDollarSign,
  Lock,
  Gift,
  AlertCircle,
  CheckCircle2,
  Clock4,
} from "lucide-react";

import { RaffleData } from "@/types";
import { useAuth } from "@/contexts/AuthContext";

interface RaffleCardProps {
  raffle: RaffleData;
}
interface CountdownTimerProps {
  endDate: Date;
  status: string;
  onTimeEnd?: () => void;
}
const CountdownTimer: React.FC<CountdownTimerProps> = ({
  endDate,
  status,
  onTimeEnd,
}) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = endDate.getTime() - now;

      if (distance <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        onTimeEnd?.();
        return null;
      }

      return {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      };
    };

    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      if (time === null) {
        clearInterval(timer);
      } else {
        setTimeLeft(time);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate, onTimeEnd]);

  if (status === "completed") {
    return <div className="text-center text-gray-600">ההגרלה הסתיימה</div>;
  }

  const TimeBox = ({ value, label }: { value: number; label: string }) => (
    <div className="text-center">
      <div className="bg-white/10 rounded-lg p-2 font-bold min-w-[2.5rem]  backdrop-blur-sm">
        {String(value).padStart(2, "0")}
      </div>
      <div className=" mt-1 text-sm">{label}</div>
    </div>
  );

  return (
    <div className="flex flex-row-reverse justify-center gap-4">
      <TimeBox value={timeLeft.days} label="ימים" />
      <TimeBox value={timeLeft.hours} label="שעות" />
      <TimeBox value={timeLeft.minutes} label="דקות" />
      <TimeBox value={timeLeft.seconds} label="שניות" />
    </div>
  );
};

const getRaffleTypeText = (type: string) => {
  const types = {
    oneTime: "חד פעמית",
    daily: "יומית",
    weekly: "שבועית",
    monthly: "חודשית",
  };
  return types[type as keyof typeof types] || type;
};

const getStatusStyle = (status: string) => {
  const styles = {
    active: {
      bg: "bg-green-100",
      text: "text-green-800",
      label: "פעיל",
    },
    upcoming: {
      bg: "bg-blue-100",
      text: "text-blue-800",
      label: "עתידי",
    },
    completed: {
      bg: "bg-gray-100",
      text: "text-gray-800",
      label: "הסתיים",
    },
  };
  return styles[status as keyof typeof styles] || styles.completed;
};

const RaffleCard: React.FC<RaffleCardProps> = ({ raffle }) => {
  const { currentUser } = useAuth();
  const StatusIcon = useMemo(() => {
    switch (raffle.status) {
      case "active":
        return CheckCircle2;
      case "upcoming":
        return Clock4;
      case "completed":
        return Lock;
      default:
        return AlertCircle;
    }
  }, [raffle.status]);
  // טיפול טוב יותר בתאריך סיום
  const endDate = useMemo(() => {
    try {
      return typeof raffle.endDate === "string"
        ? new Date(raffle.endDate)
        : raffle.endDate?.seconds
        ? new Date(raffle.endDate.seconds * 1000)
        : new Date();
    } catch (error) {
      console.error("Error parsing end date:", error);
      return new Date();
    }
  }, [raffle.endDate]);

  // טיפול בפורמט זמן עם ערכי ברירת מחדל
  const timeLeft = useMemo(() => {
    try {
      return formatDistance(endDate, new Date(), {
        addSuffix: true,
        locale: he,
      });
    } catch (error) {
      return "זמן לא זמין";
    }
  }, [endDate]);

  // טיפול בסטטוס לא מוגדר
  const statusStyle = useMemo(
    () => getStatusStyle(raffle.status || "completed"),
    [raffle.status]
  );

  // טיפול בערכים חסרים
  const safeRaffle = useMemo(
    () => ({
      ...raffle,
      title: raffle.title || "הגרלה ללא שם",
      description: raffle.description || "אין תיאור",
      imageUrl: raffle.imageUrl || "/default-raffle-image.jpg",
      pointsCost: raffle.pointsCost || 0,
      totalParticipants: raffle.totalParticipants || 0,
      type: raffle.type || "oneTime",
      raffleTime: raffle.raffleTime || "00:00",
      status: raffle.status || "completed",
    }),
    [raffle]
  );

  // בדיקת תקינות זמן הגרלה
  const formattedRaffleTime = useMemo(() => {
    try {
      return new Date(`2000-01-01T${safeRaffle.raffleTime}`).toLocaleTimeString(
        "he-IL",
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }
      );
    } catch (error) {
      return "00:00";
    }
  }, [safeRaffle.raffleTime]);

  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="relative p-0">
        <img
          src={safeRaffle.imageUrl}
          alt={safeRaffle.title}
          className="w-full h-48 object-cover rounded-t-lg"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = "/default-raffle-image.jpg";
          }}
        />

        {safeRaffle.status === "completed" && safeRaffle.winner && (
          <div className="absolute inset-0 bg-black/70 flex flex-col items-center justify-center text-white">
            <Gift className="w-12 h-12 mb-2 text-yellow-500" />
            <h3 className="text-xl font-bold mb-1">🎉 הזוכה בהגרלה 🎉</h3>
            <p className="text-lg">{safeRaffle.winner.username}</p>
            {currentUser?.id === safeRaffle.winner.userId && (
              <div className="mt-2 p-2 bg-white/10 rounded">
                <p className="text-sm font-mono">
                  קוד: {safeRaffle.winner.code || "לא זמין"}
                </p>
              </div>
            )}
          </div>
        )}

        {/* Status badges */}
        <div className="absolute top-2 left-2 bg-black/50 text-white px-3 py-1 rounded-full text-sm flex items-center">
          <CircleDollarSign className="w-4 h-4 ml-1" />
          {safeRaffle.pointsCost} נקודות
        </div>

        <div
          className={`absolute top-2 right-2 px-3 py-1 rounded-full text-sm 
          ${statusStyle.bg} ${statusStyle.text} flex items-center`}
        >
          <StatusIcon className="w-4 h-4 ml-1" />
          {statusStyle.label}
        </div>

        {/* Rest of the badges */}
        <div className="absolute bottom-2 right-2 bg-black/50 text-white px-3 py-1 rounded-full text-sm flex items-center">
          <Calendar className="w-4 h-4 ml-1" />
          {getRaffleTypeText(safeRaffle.type)}
        </div>
      </CardHeader>

      <CardContent className="flex-grow p-4">
        {/* Content remains mostly the same but uses safeRaffle */}

        {(safeRaffle.status === "upcoming" ||
          safeRaffle.status === "active") && (
          <div className="mb-4">
            <div className="text-sm font-semibold mb-2 flex items-center gap-2">
              <Timer className="w-4 h-4 text-blue-500" />
              {safeRaffle.status === "upcoming"
                ? "זמן עד לפתיחה"
                : "זמן עד לסיום ההגרלה"}
            </div>
            <CountdownTimer endDate={endDate} status={safeRaffle.status} />
          </div>
        )}
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-blue-500" />
            <span>{timeLeft}</span>
          </div>
          <div className="flex items-center gap-2">
            <Users className="w-4 h-4 text-green-500" />
            <span>{safeRaffle.totalParticipants} משתתפים</span>
          </div>
        </div>

        {safeRaffle.type !== "oneTime" && (
          <div className="mt-2 flex items-center justify-center gap-2 text-sm text-gray-500">
            <Clock className="w-4 h-4 text-purple-500" />
            <span>זמן הגרלה:</span>
            <span className="font-medium">{formattedRaffleTime}</span>
          </div>
        )}
      </CardContent>

      <CardFooter className="p-4 pt-0">
        <Button
          asChild
          className="w-full"
          disabled={safeRaffle.status !== "active"}
        >
          <Link
            to={`/raffle/${safeRaffle.id}`}
            className="flex items-center justify-center gap-2"
          >
            {safeRaffle.status === "active" ? (
              <>
                <Ticket className="w-4 h-4" />
                השתתף בהגרלה
              </>
            ) : safeRaffle.status === "upcoming" ? (
              <>
                <Clock className="w-4 h-4" />
                בקרוב
              </>
            ) : (
              <>
                <Lock className="w-4 h-4" />
                ההגרלה הסתיימה
              </>
            )}
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
};

export default RaffleCard;
