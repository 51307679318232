import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import useShabbatMode from "@/hooks/useShabbatMode";

// Extend Window interface to include adsbygoogle
declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

// Types
type AdType = "opening" | "between-raffles" | "raffle-page" | "bottom-page";
type AnchorPosition = "bottom" | "top" | "none";

interface AdSize {
  width: string;
  height: string;
}

interface AdBannerProps {
  type: AdType;
  anchorPosition?: AnchorPosition;
  className?: string;
}

// Constants
const AD_SLOTS: Record<AdType, string> = {
  opening: "5890264731",
  "between-raffles": "5890264731",
  "raffle-page": "5890264731",
  "bottom-page": "5890264731",
};

const AD_SIZES: Record<AdType, AdSize> = {
  opening: { width: "100%", height: "250px" },
  "between-raffles": { width: "320px", height: "100px" },
  "raffle-page": { width: "728px", height: "90px" },
  "bottom-page": { width: "970px", height: "250px" },
};

const CLIENT_ID = "ca-pub-7232224177198894";
const TIMEOUT_DURATION = 20000;

// Improved AdSense Manager with TypeScript types
class AdSenseManager {
  private static instance: AdSenseManager;
  private scriptPromise: Promise<void> | null = null;
  private pageAdsInitialized = false;

  private constructor() {}

  static getInstance(): AdSenseManager {
    if (!AdSenseManager.instance) {
      AdSenseManager.instance = new AdSenseManager();
    }
    return AdSenseManager.instance;
  }

  private loadScript(): Promise<void> {
    if (this.scriptPromise) {
      return this.scriptPromise;
    }

    this.scriptPromise = new Promise((resolve, reject) => {
      if (typeof window === "undefined") {
        reject(new Error("Window is not defined"));
        return;
      }

      // Check if script is already loaded
      if (document.querySelector('script[src*="adsbygoogle.js"]')) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${CLIENT_ID}`;
      script.async = true;
      script.crossOrigin = "anonymous";

      script.onload = () => resolve();
      script.onerror = () => reject(new Error("AdSense script failed to load"));

      document.head.appendChild(script);
    });

    return this.scriptPromise;
  }

  async initializePageAds(
    anchorPosition: AnchorPosition = "none"
  ): Promise<void> {
    if (this.pageAdsInitialized || typeof window === "undefined") {
      return;
    }

    try {
      await this.loadScript();

      if (typeof window.adsbygoogle !== "undefined") {
        window.adsbygoogle = window.adsbygoogle || [];
        if (!this.pageAdsInitialized) {
          window.adsbygoogle.push({
            google_ad_client: CLIENT_ID,
            enable_page_level_ads: true,
            ...(anchorPosition !== "none" && {
              overlays: {
                bottom: anchorPosition === "bottom",
                top: anchorPosition === "top",
              },
            }),
          });
          this.pageAdsInitialized = true;
        }
      }
    } catch (error) {
      console.error("Failed to initialize page-level ads:", error);
      throw error;
    }
  }

  async pushAd(): Promise<void> {
    if (typeof window === "undefined") return;

    try {
      await this.loadScript();

      if (typeof window.adsbygoogle !== "undefined") {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error("Failed to push ad:", error);
      throw error;
    }
  }
}

// AdBanner component
const AdBanner: React.FC<AdBannerProps> = ({
  type,
  anchorPosition = "none",
  className = "",
}) => {
  const { isShabbat } = useShabbatMode();
  const [isVisible] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const adRef = useRef<HTMLDivElement>(null);
  const initializedRef = useRef(false);
  const adManager = AdSenseManager.getInstance();

  const initializeAd = useCallback(async () => {
    if (isShabbat || !isVisible || initializedRef.current) {
      return;
    }

    try {
      await adManager.initializePageAds(anchorPosition);

      if (adRef.current && !initializedRef.current) {
        await adManager.pushAd();
        initializedRef.current = true;
        setIsLoaded(true);
        setError(null);
      }
    } catch (err) {
      console.error("Ad initialization error:", err);
      setError(err instanceof Error ? err.message : "שגיאה בטעינת הפרסומת");
      setIsLoaded(false);
    }
  }, [isShabbat, isVisible, anchorPosition]);

  useEffect(() => {
    if (!adRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !initializedRef.current) {
          initializeAd();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(adRef.current);
    return () => observer.disconnect();
  }, [initializeAd]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!isLoaded && isVisible && !error) {
      timeoutId = setTimeout(() => {
        setError("הפרסומת לא נטענה בזמן סביר");
      }, TIMEOUT_DURATION);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isLoaded, isVisible, error]);

  if (isShabbat || !isVisible) return null;

  const adSize = AD_SIZES[type];

  return (
    <motion.div
      ref={adRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`relative w-full flex justify-center my-4 ${className}`}
      role="complementary"
      aria-label="פרסומת"
    >
      <div
        className="relative glass rounded-lg overflow-hidden"
        style={{
          width: adSize.width,
          height: adSize.height,
          backgroundColor: "transparent",
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: "inline-block",
            width: adSize.width,
            height: adSize.height,
          }}
          data-ad-client={CLIENT_ID}
          data-ad-slot={AD_SLOTS[type]}
          data-ad-format="auto"
          data-full-width-responsive="true"
          data-adtest="on"
        />

        {!isLoaded && !error && (
          <div
            className="absolute inset-0 flex items-center justify-center bg-black/10"
            role="status"
            aria-label="טוען פרסומת"
          >
            <div className="text-white/70 text-sm">טוען פרסומת...</div>
          </div>
        )}

        {error && (
          <div
            className="absolute inset-0 flex items-center justify-center bg-red-500/10"
            role="alert"
          >
            <div className="text-red-400 text-sm">{error}</div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default React.memo(AdBanner);
