// src/components/NagishliWidget.tsx
import { useEffect } from 'react';

export const NagishliWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://nagish.li/live/load.js";
    script.async = true;
    
    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return null;
};