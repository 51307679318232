import { useEffect, useState } from "react";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "@/lib/firebase";
import confetti from "canvas-confetti";
import { useToast } from "@/components/common/Use-toast";

interface AutoRaffleDrawProps {
  raffle: any;
  setSpinning: (spinning: boolean) => void;
  setPrizeNumber: (number: number) => void;
}

const AutoRaffleDraw = ({
  raffle,
  setSpinning,
  setPrizeNumber,
}: AutoRaffleDrawProps) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const { toast } = useToast();

  const triggerWinningAnimation = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;

    const randomInRange = (min: number, max: number) => {
      return Math.random() * (max - min) + min;
    };

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();
      if (timeLeft <= 0) return clearInterval(interval);

      confetti({
        particleCount: 3,
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        origin: { x: randomInRange(0.1, 0.9), y: randomInRange(0.1, 0.9) },
        colors: ["#FFD700", "#FFA500", "#FF4500"],
        zIndex: 9999,
      });
    }, 50);
  };

  useEffect(() => {
    const checkAndDrawWinner = async () => {
      if (isDrawing || !raffle) return;

      try {
        const now = new Date();
        const endDate =
          raffle.endDate instanceof Timestamp
            ? raffle.endDate.toDate()
            : new Date(raffle.endDate);

        const [hours, minutes] = (raffle.scheduleTime || "00:00").split(":");
        const raffleDateTime = new Date(endDate);
        raffleDateTime.setHours(parseInt(hours), parseInt(minutes), 0, 0);

        const timeDiff = raffleDateTime.getTime() - now.getTime(); // הפוך את הסדר
        console.log("Current time:", now);
        console.log("Raffle time:", raffleDateTime);
        console.log("Time diff:", timeDiff);

        if (timeDiff <= 0 && !raffle.winner) {
          console.log("Starting raffle draw...");
          setIsDrawing(true);

          if (!raffle.participants?.length) {
            await updateDoc(doc(db, "raffles", raffle.id), {
              status: "completed",
              endDate: Timestamp.now(),
            });
            return;
          }

          const weightedParticipants: string[] = [];
          raffle.participants.forEach(
            (participant: { id: string; entries: number }) => {
              for (let i = 0; i < participant.entries; i++) {
                weightedParticipants.push(participant.id);
              }
            }
          );

          const winnerIndex = Math.floor(
            Math.random() * weightedParticipants.length
          );
          const winnerId = weightedParticipants[winnerIndex];
          const winner = raffle.participants.find(
            (p: { id: string; entries: number; username: string }) =>
              p.id === winnerId
          );

          const displayIndex = raffle.participants.findIndex(
            (p: { id: string; entries: number }) => p.id === winnerId
          );
          setPrizeNumber(displayIndex);
          setSpinning(true);

          const availableCode = raffle.prizeCodes.find(
            (code: { isRevealed: boolean }) => !code.isRevealed
          );
          if (!availableCode) throw new Error("No available prize code");

          setTimeout(async () => {
            await updateDoc(doc(db, "raffles", raffle.id), {
              winner: {
                userId: winner.id,
                username: winner.username,
                code: availableCode.code,
              },
              status: "completed",
              endDate: Timestamp.now(),
              ["prizeCodes." + availableCode.id + ".isRevealed"]: true,
              ["prizeCodes." + availableCode.id + ".claimedBy"]: winner.id,
              ["prizeCodes." + availableCode.id + ".revealDate"]:
                Timestamp.now(),
            });

            toast({
              title: "🎉 יש לנו זוכה! 🎉",
              description: `מזל טוב ל${winner.username}!`,
            });

            triggerWinningAnimation();
            setSpinning(false);
            setIsDrawing(false);
          }, 10000);
        }
      } catch (error) {
        console.error("Error in raffle draw:", error);
        setSpinning(false);
        setIsDrawing(false);
        toast({
          title: "שגיאה בהגרלה",
          description: "אירעה שגיאה בביצוע ההגרלה",
          variant: "destructive",
        });
      }
    };

    const timer = setInterval(checkAndDrawWinner, 1000);
    return () => clearInterval(timer);
  }, [raffle, isDrawing, setPrizeNumber, setSpinning, toast]);

  return null;
};

export default AutoRaffleDraw;
