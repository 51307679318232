import { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  Timestamp,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";

import { Plus, Edit2, Trash2, HelpCircle } from "lucide-react";
import { Label } from "@/components/common/Label";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { useToast } from "@/components/common/Use-toast";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Switch } from "@/components/ui/switch";
import DOMPurify from "dompurify";
import { Textarea } from "@/components/common/Textarea";

import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
const FORMATTING_HELP = `
עיצוב טקסט זמין:
- **טקסט מודגש**
- *טקסט נטוי*
- __קו תחתון__
- ##טקסט גדול##
- ###טקסט גדול יותר###
- ~~קו חוצה~~
- ^^טקסט קטן^^
- {{red}}טקסט אדום{{/red}}
- {{blue}}טקסט כחול{{/blue}}
- {{green}}טקסט ירוק{{/green}}
`;
interface Announcement {
  id: string;
  title: string;
  content: string;
  type: "info" | "warning" | "success";
  active: boolean;
  expiresAt: Timestamp | null;
  createdAt: Timestamp;
}

interface FormData {
  title: string;
  content: string;
  type: "info" | "warning" | "success";
  active: boolean;
  expiresAt: string;
  
}

const AdminAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingAnnouncement, setEditingAnnouncement] =
    useState<Announcement | null>(null);
  const [formData, setFormData] = useState<FormData>({
    title: "",
    content: "",
    type: "info",
    active: true,
    expiresAt: "",
  });
  const { toast } = useToast();

  useEffect(() => {
    const q = query(
      collection(db, "announcements"),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot: QuerySnapshot<DocumentData>) => {
        const announcementsData: Announcement[] = [];
        querySnapshot.forEach((doc) => {
          announcementsData.push({
            id: doc.id,
            ...doc.data(),
          } as Announcement);
        });
        setAnnouncements(announcementsData);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleSubmit = async () => {
    try {
      const data = {
        title: formData.title,
        content: formData.content,
        type: formData.type,
        active: formData.active,
        expiresAt: formData.expiresAt
          ? Timestamp.fromDate(new Date(formData.expiresAt))
          : null,
        createdAt: editingAnnouncement?.createdAt || serverTimestamp(),
      };

      if (editingAnnouncement) {
        await updateDoc(doc(db, "announcements", editingAnnouncement.id), data);
        toast({
          title: "ההודעה עודכנה בהצלחה",
        });
      } else {
        await addDoc(collection(db, "announcements"), data);
        toast({
          title: "ההודעה נוספה בהצלחה",
        });
      }

      setShowAddModal(false);
      setEditingAnnouncement(null);
      setFormData({
        title: "",
        content: "",
        type: "info",
        active: true,
        expiresAt: "",
      });
    } catch (error) {
      toast({
        title: "שגיאה בשמירת ההודעה",
        variant: "destructive",
      });
    }
  };

  const handleEdit = (announcement: Announcement) => {
    setEditingAnnouncement(announcement);
    setFormData({
      title: announcement.title,
      content: announcement.content,
      type: announcement.type,
      active: announcement.active,
      expiresAt: announcement.expiresAt
        ? new Date(announcement.expiresAt.seconds * 1000)
            .toISOString()
            .split("T")[0]
        : "",
    });
    setShowAddModal(true);
  };

  const handleDelete = async (id: string) => {
    if (confirm("האם אתה בטוח שברצונך למחוק את ההודעה?")) {
      try {
        await deleteDoc(doc(db, "announcements", id));
        toast({
          title: "ההודעה נמחקה בהצלחה",
        });
      } catch (error) {
        toast({
          title: "שגיאה במחיקת ההודעה",
          variant: "destructive",
        });
      }
    }
  };

  const getTypeDisplay = (type: string) => {
    const types = {
      info: { text: "מידע", classes: "bg-blue-100 text-blue-800" },
      warning: { text: "אזהרה", classes: "bg-yellow-100 text-yellow-800" },
      success: { text: "הצלחה", classes: "bg-green-100 text-green-800" },
    } as const;

    if (!type || !(type in types)) {
      return { text: "מידע", classes: "bg-blue-100 text-blue-800" };
    }

    return types[type as keyof typeof types];
  };

  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <h2 className="text-2xl font-bold">ניהול הודעות</h2>
          <Button onClick={() => setShowAddModal(true)}>
            <Plus className="w-4 h-4 ml-2" />
            הוסף הודעה חדשה
          </Button>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>כותרת</TableHead>
                <TableHead>תוכן</TableHead>
                <TableHead>סוג</TableHead>
                <TableHead>פעיל</TableHead>
                <TableHead>תאריך תפוגה</TableHead>
                <TableHead>פעולות</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {announcements.map((announcement) => (
                <TableRow key={announcement.id}>
                  <TableCell className="font-medium">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(announcement.title),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(announcement.content),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        getTypeDisplay(announcement.type)?.classes || ""
                      }`}
                    >
                      {getTypeDisplay(announcement.type)?.text || "מידע"}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={announcement.active}
                      onCheckedChange={async (checked) => {
                        try {
                          await updateDoc(
                            doc(db, "announcements", announcement.id),
                            {
                              active: checked,
                            }
                          );
                          toast({
                            title: `ההודעה ${
                              checked ? "הופעלה" : "הושבתה"
                            } בהצלחה`,
                          });
                        } catch (error) {
                          toast({
                            title: "שגיאה בעדכון הסטטוס",
                            variant: "destructive",
                          });
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {announcement.expiresAt
                      ? new Date(
                          announcement.expiresAt.seconds * 1000
                        ).toLocaleDateString("he-IL")
                      : "ללא תפוגה"}
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleEdit(announcement)}
                      >
                        <Edit2 className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(announcement.id)}
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={showAddModal} onOpenChange={setShowAddModal}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>
              {editingAnnouncement ? "ערוך הודעה" : "הוסף הודעה חדשה"}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <Label htmlFor="title">כותרת</Label>
                <div className="relative">
                  <Input
                    id="title"
                    value={formData.title}
                    onChange={(e) =>
                      setFormData({ ...formData, title: e.target.value })
                    }
                  />
                </div>
              </div>
              <TooltipProvider>
                <Tooltip delayDuration={300}>
                  <TooltipTrigger asChild>
                    <Button variant="ghost" size="icon" className="mr-2">
                      <HelpCircle className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <pre className="text-sm whitespace-pre-wrap">
                      {FORMATTING_HELP}
                    </pre>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            <div>
              <Label htmlFor="content">תוכן</Label>
              <Textarea
                id="content"
                value={formData.content}
                onChange={(e) =>
                  setFormData({ ...formData, content: e.target.value })
                }
                className="min-h-[100px]"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="type">סוג</Label>
                <Select
                  value={formData.type}
                  onValueChange={(value: "info" | "warning" | "success") =>
                    setFormData({ ...formData, type: value })
                  }
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="info">מידע</SelectItem>
                    <SelectItem value="warning">אזהרה</SelectItem>
                    <SelectItem value="success">הצלחה</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label htmlFor="expiresAt">תאריך תפוגה (אופציונלי)</Label>
                <Input
                  id="expiresAt"
                  type="date"
                  value={formData.expiresAt}
                  onChange={(e) =>
                    setFormData({ ...formData, expiresAt: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="active"
                checked={formData.active}
                onCheckedChange={(checked: boolean) =>
                  setFormData({ ...formData, active: checked })
                }
              />
              <Label htmlFor="active">פעיל</Label>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <h3 className="font-semibold mb-2">תצוגה מקדימה:</h3>
              <div className="bg-white p-3 rounded border">
                <span
                  className="font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formData.title),
                  }}
                />
                <span>: </span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(formData.content),
                  }}
                />
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setShowAddModal(false)}>
              ביטול
            </Button>
            <Button onClick={handleSubmit}>
              {editingAnnouncement ? "עדכן" : "הוסף"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminAnnouncements;
