import { motion } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { articles } from "./data/articlesData";

const ArticlesCategoryPage = () => {
  const { category } = useParams();
  const filteredArticles = articles.filter(
    (article) => article.category === category
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500 py-8"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <Link
            to="/articles"
            className="text-white/80 hover:text-white mb-4 inline-block"
          >
            ← חזרה לכל המאמרים
          </Link>
          <h1 className="text-3xl font-bold text-white">{category}</h1>
        </div>

        {/* Articles List */}
        <div className="space-y-6">
          {filteredArticles.map((article) => (
            <motion.div
              key={article.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/10 backdrop-blur-sm rounded-lg overflow-hidden
                         hover:bg-white/20 transition-all duration-200"
            >
              <Link to={`/articles/${category}/${article.id}`}>
                <div className="p-6">
                  <h2 className="text-xl font-bold text-white mb-2">
                    {article.title}
                  </h2>
                  <p className="text-white/70 line-clamp-3 mb-4">
                    {article.content}
                  </p>

                  <div className="flex items-center justify-between text-sm text-white/60">
                    <div className="flex items-center gap-4">
                      <span>{article.author}</span>
                      <span>{article.date}</span>
                    </div>
                    <div className="flex items-center gap-4">
                      <span>👁 {article.views}</span>
                      <span>❤️ {article.likes}</span>
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ArticlesCategoryPage;
