import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Edit2, Trash2, Loader2 } from "lucide-react";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { DialogHeader, DialogFooter } from "@/components/common/Dialog";
import { Textarea } from "@/components/common/Textarea";
import { Dialog, DialogContent, DialogTitle } from "@radix-ui/react-dialog";
import { Label } from "recharts";

// Types
type MissionType = "upload" | "video" | "code" | "social";
type MissionStatus = "active" | "inactive";
type MissionFrequency = "once" | "daily" | "weekly" | "monthly";

interface AdReward {
  enabled: boolean;
  pointsPerView: number;
  maxViewsPerDay: number;
}

interface FormData {
  title: string;
  description: string;
  type: MissionType;
  points: number;
  status: MissionStatus;
  imageUrl: string;
  instructionsImageUrl: string;
  requirements: string;
  frequency: MissionFrequency;
  // Upload mission fields
  contentInstructions: string;
  maxFileSize: number;
  allowedTypes: string[];
  requiredScreenshots: number;
  // Video mission fields
  videoUrl: string;
  minimumTimeSeconds: number;
  // Code mission fields
  codeInstructions: string;
  validCodes: string[];
  maxAttempts: number;
  // Social mission fields
  platform: string;
  sharingText: string;
  shareLink: string;
  adReward: AdReward;
}

interface Mission extends FormData {
  id: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  lastCompletedAt: Timestamp | null;
  nextAvailableAt: Timestamp | null;
}

// Constants
const MISSION_FREQUENCIES: Record<MissionFrequency, string> = {
  once: "חד פעמי",
  daily: "יומי",
  weekly: "שבועי",
  monthly: "חודשי",
};

const MISSION_STATUSES: Record<MissionStatus, string> = {
  active: "פעיל",
  inactive: "לא פעיל",
};

const DEFAULT_FORM_DATA: FormData = {
  title: "",
  description: "",
  type: "upload",
  points: 10,
  status: "inactive",
  imageUrl: "",
  instructionsImageUrl: "",
  requirements: "",
  frequency: "once",
  contentInstructions: "",
  maxFileSize: 5,
  allowedTypes: ["image/jpeg", "image/png"],
  requiredScreenshots: 1,
  videoUrl: "",
  minimumTimeSeconds: 30,
  codeInstructions: "",
  validCodes: [],
  maxAttempts: 3,
  platform: "whatsapp",
  sharingText: "",
  shareLink: "",
  adReward: {
    enabled: false,
    pointsPerView: 1,
    maxViewsPerDay: 1,
  },
};

const MissionManager: React.FC = () => {
  const [missions, setMissions] = useState<Mission[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingMission, setEditingMission] = useState<Mission | null>(null);
  const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    console.log("Setting up Firebase listener");
    const q = query(collection(db, "missions"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        console.log("Got Firebase update, documents:", querySnapshot.size);
        const missionsData: Mission[] = [];
        querySnapshot.forEach((doc) => {
          console.log("Document data:", doc.data());
          missionsData.push({ id: doc.id, ...doc.data() } as Mission);
        });
        setMissions(missionsData);
      },
      (error) => {
        console.error("Firebase error:", error);
      }
    );

    return () => unsubscribe();
  }, []);
  const calculateNextAvailable = (
    frequency: MissionFrequency,
    date: Date
  ): Date | null => {
    switch (frequency) {
      case "once":
        return null;
      case "daily":
        const tomorrow = new Date(date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        return tomorrow;
      case "weekly":
        const nextWeek = new Date(date);
        nextWeek.setDate(nextWeek.getDate() + 7);
        nextWeek.setHours(0, 0, 0, 0);
        return nextWeek;
      case "monthly":
        const nextMonth = new Date(date);
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setHours(0, 0, 0, 0);
        return nextMonth;
      default:
        return null;
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const nextAvailable = calculateNextAvailable(
        formData.frequency,
        new Date()
      );
      const submissionData = {
        ...formData,
        nextAvailableAt: nextAvailable
          ? Timestamp.fromDate(nextAvailable)
          : null,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        lastCompletedAt: null,
      };

      if (editingMission) {
        await updateDoc(doc(db, "missions", editingMission.id), submissionData);
      } else {
        await addDoc(collection(db, "missions"), submissionData);
      }

      setShowModal(false);
      setEditingMission(null);
      setFormData(DEFAULT_FORM_DATA);
    } catch (error) {
      console.error("Error saving mission:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateForm = (): boolean => {
    if (!formData.title.trim() || !formData.description.trim()) {
      alert("יש להזין כותרת ותיאור למשימה");
      return false;
    }

    switch (formData.type) {
      case "upload":
        if (!formData.contentInstructions?.trim()) {
          alert("יש להזין הוראות למשימה");
          return false;
        }
        break;
      case "video":
        if (!formData.videoUrl?.trim()) {
          alert("יש להזין קישור לסרטון");
          return false;
        }
        break;
      case "code":
        if (!formData.validCodes?.length) {
          alert("יש להזין לפחות קוד אחד תקף");
          return false;
        }
        break;
      case "social":
        if (!formData.sharingText?.trim()) {
          alert("יש להזין טקסט לשיתוף");
          return false;
        }
        break;
    }

    return true;
  };

  const handleDelete = async (missionId: string) => {
    if (confirm("האם אתה בטוח שברצונך למחוק את המשימה?")) {
      try {
        await deleteDoc(doc(db, "missions", missionId));
      } catch (error) {
        console.error("Error deleting mission:", error);
      }
    }
  };

  const renderTypeSpecificFields = () => {
    switch (formData.type) {
      case "upload":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>תוכן המשימה</Label>
              <Textarea
                value={formData.contentInstructions}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    contentInstructions: e.target.value,
                  })
                }
                placeholder="הכנס את תוכן המשימה"
                className="min-h-[100px]"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label>גודל קובץ מקסימלי (MB)</Label>
                <Input
                  type="number"
                  min={1}
                  value={formData.maxFileSize}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      maxFileSize: Number(e.target.value),
                    })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label>מספר צילומי מסך</Label>
                <Input
                  type="number"
                  min={1}
                  value={formData.requiredScreenshots}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      requiredScreenshots: Number(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </div>
        );

      case "video":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>קישור לסרטון</Label>
              <Input
                value={formData.videoUrl}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    videoUrl: e.target.value,
                  })
                }
                placeholder="הכנס קישור לסרטון"
                dir="ltr"
              />
            </div>
            <div className="space-y-2">
              <Label>זמן צפייה מינימלי (שניות)</Label>
              <Input
                type="number"
                min={1}
                value={formData.minimumTimeSeconds}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimumTimeSeconds: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        );

      case "code":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>קודים תקפים (אחד בכל שורה)</Label>
              <Textarea
                value={formData.validCodes.join("\n")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    validCodes: e.target.value
                      .split("\n")
                      .filter((code) => code.trim()),
                  })
                }
                placeholder="הכנס קודים, קוד אחד בכל שורה"
                className="min-h-[100px]"
                dir="ltr"
              />
            </div>
            <div className="space-y-2">
              <Label>הוראות שימוש בקוד</Label>
              <Textarea
                value={formData.codeInstructions}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    codeInstructions: e.target.value,
                  })
                }
                placeholder="הכנס הוראות לשימוש בקוד"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <Label>מספר ניסיונות מקסימלי</Label>
              <Input
                type="number"
                min={1}
                value={formData.maxAttempts}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    maxAttempts: Number(e.target.value),
                  })
                }
              />
            </div>
          </div>
        );

      case "social":
        return (
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>פלטפורמת שיתוף</Label>
              <Select
                value={formData.platform}
                onValueChange={(value) =>
                  setFormData({
                    ...formData,
                    platform: value,
                  })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="בחר פלטפורמה" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="whatsapp">WhatsApp</SelectItem>
                  <SelectItem value="facebook">Facebook</SelectItem>
                  <SelectItem value="telegram">Telegram</SelectItem>
                  <SelectItem value="instagram">Instagram</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label>טקסט לשיתוף</Label>
              <Textarea
                value={formData.sharingText}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    sharingText: e.target.value,
                  })
                }
                placeholder="הכנס את הטקסט לשיתוף"
                className="min-h-[100px]"
              />
            </div>
            <div className="space-y-2">
              <Label>קישור לשיתוף</Label>
              <Input
                value={formData.shareLink}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    shareLink: e.target.value,
                  })
                }
                placeholder="הכנס קישור לשיתוף"
                dir="ltr"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <Card className="container mx-auto py-6">
      <CardHeader className="flex flex-row items-center justify-between">
        <h2 className="text-2xl font-bold">ניהול משימות</h2>
        <Button
          onClick={() => {
            setEditingMission(null);
            setFormData(DEFAULT_FORM_DATA);
            setShowModal(true);
          }}
        >
          הוסף משימה חדשה
        </Button>
      </CardHeader>

      <CardContent>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>כותרת</TableHead>
                <TableHead>סוג</TableHead>
                <TableHead>נקודות</TableHead>
                <TableHead>תדירות</TableHead>
                <TableHead>סטטוס</TableHead>
                <TableHead>פעולות</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {missions.map((mission) => (
                <TableRow key={mission.id}>
                  <TableCell className="font-medium">
                    <div className="space-y-1">
                      <div>{mission.title}</div>
                      <div className="text-sm text-gray-500">
                        {mission.description}
                      </div>
                      <div className="flex gap-2">
                        {mission.imageUrl && (
                          <img
                            src={mission.imageUrl}
                            alt="תמונה ראשית"
                            className="mt-2 max-w-[100px] h-auto rounded-md"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = "/placeholder-image.jpg";
                            }}
                          />
                        )}
                        {mission.instructionsImageUrl && (
                          <img
                            src={mission.instructionsImageUrl}
                            alt="תמונת הוראות"
                            className="mt-2 max-w-[100px] h-auto rounded-md"
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.src = "/placeholder-image.jpg";
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {mission.type === "upload" && "העלאת תוכן"}
                    {mission.type === "video" && "צפייה בסרטון"}
                    {mission.type === "code" && "קוד בחנות"}
                    {mission.type === "social" && "שיתוף"}
                  </TableCell>
                  <TableCell>{mission.points}</TableCell>
                  <TableCell>
                    {MISSION_FREQUENCIES[mission.frequency]}
                    {mission.nextAvailableAt && (
                      <div className="text-sm text-gray-500">
                        זמין שוב:{" "}
                        {new Date(
                          mission.nextAvailableAt.seconds * 1000
                        ).toLocaleDateString("he-IL")}
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={mission.status}
                      onValueChange={(value: MissionStatus) => {
                        updateDoc(doc(db, "missions", mission.id), {
                          status: value,
                          updatedAt: Timestamp.now(),
                        });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue>
                          {MISSION_STATUSES[mission.status]}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(MISSION_STATUSES).map(
                          ([key, label]) => (
                            <SelectItem key={key} value={key as MissionStatus}>
                              {label}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                          setEditingMission(mission);
                          setFormData({
                            ...DEFAULT_FORM_DATA,
                            ...mission,
                          });
                          setShowModal(true);
                        }}
                      >
                        <Edit2 className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDelete(mission.id)}
                      >
                        <Trash2 className="h-4 w-4 text-red-600" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Dialog open={showModal} onOpenChange={setShowModal}>
          <DialogContent className="max-w-2xl">
            <DialogHeader>
              <DialogTitle>
                {editingMission ? "עריכת משימה" : "הוספת משימה חדשה"}
              </DialogTitle>
            </DialogHeader>

            <div className="space-y-4 my-4">
              {!editingMission && (
                <div className="space-y-2">
                  <Label>סוג משימה</Label>
                  <Select
                    value={formData.type}
                    onValueChange={(value: MissionType) => {
                      setFormData({
                        ...DEFAULT_FORM_DATA,
                        type: value,
                        title: formData.title,
                        description: formData.description,
                        points: formData.points,
                        frequency: formData.frequency,
                        status: formData.status,
                      });
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="בחר סוג משימה" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="upload">העלאת תוכן</SelectItem>
                      <SelectItem value="video">צפייה בסרטון</SelectItem>
                      <SelectItem value="code">קוד בחנות</SelectItem>
                      <SelectItem value="social">שיתוף</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}

              <div className="space-y-2">
                <Label>כותרת</Label>
                <Input
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  placeholder="הכנס כותרת למשימה"
                />
              </div>

              <div className="space-y-2">
                <Label>תיאור</Label>
                <Textarea
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  placeholder="הכנס תיאור למשימה"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>נקודות</Label>
                  <Input
                    type="number"
                    min={0}
                    value={formData.points}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        points: Number(e.target.value),
                      })
                    }
                  />
                </div>

                <div className="space-y-2">
                  <Label>תדירות</Label>
                  <Select
                    value={formData.frequency}
                    onValueChange={(value: MissionFrequency) =>
                      setFormData({ ...formData, frequency: value })
                    }
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="בחר תדירות" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.entries(MISSION_FREQUENCIES).map(
                        ([key, label]) => (
                          <SelectItem key={key} value={key as MissionFrequency}>
                            {label}
                          </SelectItem>
                        )
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              {renderTypeSpecificFields()}

              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>תמונה ראשית (URL)</Label>
                  <Input
                    value={formData.imageUrl}
                    onChange={(e) =>
                      setFormData({ ...formData, imageUrl: e.target.value })
                    }
                    placeholder="הכנס קישור לתמונה ראשית"
                    dir="ltr"
                  />
                  {formData.imageUrl && (
                    <img
                      src={formData.imageUrl}
                      alt="תצוגה מקדימה"
                      className="mt-2 max-w-full h-auto rounded-md"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = "/placeholder-image.jpg";
                      }}
                    />
                  )}
                </div>

                <div className="space-y-2">
                  <Label>תמונת הוראות/דוגמא (URL)</Label>
                  <Input
                    value={formData.instructionsImageUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        instructionsImageUrl: e.target.value,
                      })
                    }
                    placeholder="הכנס קישור לתמונת הוראות"
                    dir="ltr"
                  />
                  {formData.instructionsImageUrl && (
                    <img
                      src={formData.instructionsImageUrl}
                      alt="תצוגה מקדימה"
                      className="mt-2 max-w-full h-auto rounded-md"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = "/placeholder-image.jpg";
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setShowModal(false);
                  setEditingMission(null);
                  setFormData(DEFAULT_FORM_DATA);
                }}
                disabled={isSubmitting}
              >
                ביטול
              </Button>
              <Button onClick={handleSubmit} disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    {editingMission ? "מעדכן..." : "מוסיף..."}
                  </>
                ) : editingMission ? (
                  "עדכן"
                ) : (
                  "הוסף"
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default MissionManager;
