import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Loader2, SortAsc, Calendar } from "lucide-react";
import RaffleCard from "@/components/raffle/RaffleCard";

import { RaffleData } from "@/types";
import AdBanner from "@/components/common/AdBanner";
import useShabbatMode from "@/hooks/useShabbatMode";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AnnouncementsBanner from "@/components/announcements/AnnouncementsBanner";
import BetaDialog from "@/components/BetaDialog";
import CodeRedemptionAlert from "@/components/CodeRedemptionAlert";
import ArticlesSection from "./ArticlesSection";

const Home = () => {
  const [raffles, setRaffles] = useState<RaffleData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortType, setSortType] = useState<"date" | "points">("date");
  const { isShabbat } = useShabbatMode();

  useEffect(() => {
    const fetchRaffles = async () => {
      if (isShabbat) {
        setLoading(false);
        return;
      }

      try {
        const rafflesRef = collection(db, "raffles");
        const now = Timestamp.now();

        // First get active and upcoming raffles
        const statusQuery = query(
          rafflesRef,
          where("status", "in", ["upcoming", "active"])
        );

        const raffleDocs = await getDocs(statusQuery);
        const rafflesData: RaffleData[] = [];
        raffleDocs.forEach((doc) => {
          console.log("Raffle:", doc.id, doc.data());
        });

        raffleDocs.forEach((doc) => {
          const data = doc.data();
          const endDateTime = new Date(
            `${data.endDate.toDate().toISOString().split("T")[0]}T${
              data.scheduleTime
            }`
          );

          if (endDateTime > now.toDate()) {
            rafflesData.push({
              id: doc.id,
              ...data,
              participants: data.participants || [],
              prizeCodes: data.prizeCodes || [],
            } as RaffleData);
          }
        });

        // Sort manually
        const sortedRaffles = rafflesData.sort((a, b) => {
          const aDate = new Date(
            `${(a.endDate as Timestamp).toDate().toISOString().split("T")[0]}T${
              a.raffleTime
            }`
          );
          const bDate = new Date(
            `${(b.endDate as Timestamp).toDate().toISOString().split("T")[0]}T${
              b.raffleTime
            }`
          );
          return aDate.getTime() - bDate.getTime();
        });

        setRaffles(sortedRaffles);
        setError(null);
      } catch (error) {
        console.error("Error fetching raffles:", error);
        setError("אירעה שגיאה בטעינת ההגרלות");
      } finally {
        setLoading(false);
      }
    };

    fetchRaffles();
  }, [isShabbat]);

  const sortedRaffles = React.useMemo(() => {
    if (sortType === "points") {
      return [...raffles].sort((a, b) => a.pointsCost - b.pointsCost);
    }
    return [...raffles].sort(
      (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
    );
  }, [raffles, sortType]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Loader2 className="animate-spin h-8 w-8 text-blue-500 mb-4" />
        <p className="text-gray-600">טוען הגרלות...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-red-500 mb-4">{error}</p>
        <Button onClick={() => window.location.reload()} variant="outline">
          נסה שוב
        </Button>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <AnnouncementsBanner />
      <BetaDialog />
      <main className="container mx-auto px-4 py-8">
        {/* Raffles Section */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8 glass p-6 rounded-lg">
          <h1 className="text-2xl font-bold mb-4 sm:mb-0 text-white">
            הגרלות פעילות
          </h1>

          <div className="flex items-center space-x-4 space-x-reverse">
            <Select
              value={sortType}
              onValueChange={(value: "date" | "points") => setSortType(value)}
            >
              <SelectTrigger className="w-[180px] glass text-white border-white/20">
                <SelectValue placeholder="בחר מיון" />
              </SelectTrigger>
              <SelectContent className="glass border-white/20">
                <SelectItem
                  value="date"
                  className="text-white hover:bg-white/20"
                >
                  <div className="flex items-center">
                    <Calendar className="w-4 h-4 ml-2" />
                    מיון לפי תאריך
                  </div>
                </SelectItem>
                <SelectItem
                  value="points"
                  className="text-white hover:bg-white/20"
                >
                  <div className="flex items-center">
                    <SortAsc className="w-4 h-4 ml-2" />
                    מיון לפי נקודות
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        <CodeRedemptionAlert />

        {/* Raffles Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortedRaffles.map((raffle, index) => (
            <React.Fragment key={raffle.id}>
              <div className="transform hover:scale-105 transition-transform duration-300 hover:shadow-xl">
                <div className="glass rounded-lg overflow-hidden">
                  <RaffleCard raffle={raffle} />
                </div>
              </div>
              {(index + 1) % 3 === 0 && index !== sortedRaffles.length - 1 && (
                <div className="col-span-full glass rounded-lg overflow-hidden">
                  <AdBanner type="between-raffles" />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {sortedRaffles.length === 0 && (
          <div className="text-center py-12 glass rounded-lg">
            <h2 className="text-xl text-white">אין הגרלות פעילות כרגע</h2>
            <p className="text-white/80 mt-2">נסה לבדוק שוב מאוחר יותר</p>
          </div>
        )}

        {/* Articles Section */}
        <div className="mt-16">
          <div className="flex flex-col sm:flex-row justify-between items-center mb-8 glass p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 sm:mb-0 text-white">
              מדריכים ומאמרים מומלצים
            </h2>
          </div>

          <ArticlesSection
            maxArticles={3} // מציג רק 3 מאמרים בעמוד הבית
            showViewMore={true} // מציג כפתור "הצג עוד"
            showFeatured={false} // לא מציג מאמר מודגש
            showFilters={false} // לא מציג פילטרים בעמוד הבית
            className="mt-8"
          />
        </div>

        {/* Additional Banner */}
      </main>
    </div>
  );
};

export default Home;
