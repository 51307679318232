import React from "react";
import { motion } from "framer-motion";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import MobileFooter from "./MobileFooter";
import useShabbatMode from "@/hooks/useShabbatMode";

const Layout: React.FC = () => {
  const { isShabbat } = useShabbatMode();

  if (isShabbat) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-center bg-white/10 backdrop-blur-md p-8 rounded-2xl border border-white/20
                     shadow-lg"
        >
          <h1 className="text-3xl font-bold mb-4 text-white">שבת שלום</h1>
          <p className="text-white/90">האפליקציה תהיה זמינה במוצאי שבת</p>
        </motion.div>
      </motion.div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500">
      <Navbar />

      <main className="flex-1 container mx-auto px-4 py-8 relative pb-20 md:pb-8">
        {/* Background Effects */}
        <div className="absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-t from-white/[0.07] to-transparent opacity-75" />
          <div className="absolute -top-24 -right-24 w-96 h-96 bg-purple-400/30 rounded-full blur-3xl" />
          <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-pink-400/30 rounded-full blur-3xl" />
        </div>

        {/* Content Container */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative z-10 bg-white/[0.05] backdrop-blur-lg rounded-2xl 
                     border border-white/10 shadow-xl"
        >
          {/* Glassmorphism Effect */}
          <div className="absolute inset-0 bg-gradient-to-b from-white/[0.05] to-transparent rounded-2xl" />

          {/* Main Content */}
          <div className="relative p-6">
            <Outlet />
          </div>
        </motion.div>
      </main>

      {/* Desktop Footer */}
      <div className="hidden md:block">
        <Footer />
      </div>

      {/* Mobile Footer Navigation */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 z-50">
        <div className="bg-white/10 backdrop-blur-lg border-t border-white/20">
          <MobileFooter />
        </div>
      </div>

      {/* Sidebar */}
      <Sidebar isOpen={false} onClose={() => {}} />
    </div>
  );
};

export default Layout;
