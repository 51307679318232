import React from "react";

import { Button } from "@/components/ui/button";
import { Check, Star } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/common/Dialog";

interface SuccessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  points: number;
}

const SuccessDialog: React.FC<SuccessDialogProps> = ({
  isOpen,
  onClose,
  points,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-center gap-2 text-green-600 text-xl">
            <Check className="w-6 h-6" />
            הצלחה!
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          <div className="text-center bg-green-50 p-6 rounded-lg border border-green-200">
            <div className="flex justify-center mb-4">
              <div className="bg-green-600 text-white p-3 rounded-full">
                <Check className="w-8 h-8" />
              </div>
            </div>

            <h3 className="text-xl font-bold text-green-700 mb-2">
              התמונה הועלתה בהצלחה!
            </h3>

            <div className="flex items-center justify-center gap-2 text-lg text-green-600 font-semibold mt-4">
              <Star className="w-5 h-5 fill-current" />
              <span>קיבלת {points} נקודות</span>
              <Star className="w-5 h-5 fill-current" />
            </div>
          </div>

          <div className="flex justify-center">
            <Button
              onClick={onClose}
              className="bg-green-600 hover:bg-green-700 text-white min-w-[120px]"
            >
              סגור
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;
