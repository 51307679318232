// src/utils/date.ts
import { Timestamp } from "firebase/firestore";
import type { Raffle } from "@/types/raffle";
import { DAYS_OF_WEEK } from "@/constants/raffle";

export const formatDate = (timestamp: Timestamp | null) => {
  if (!timestamp) return "-";

  return new Date(timestamp.seconds * 1000).toLocaleDateString("he-IL", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const formatScheduleInfo = (raffle: Raffle): string => {
  switch (raffle.type) {
    case "daily":
      return `יומי בשעה ${raffle.scheduleTime}`;

    case "weekly":
      return `${
        DAYS_OF_WEEK.find((d) => d.value === raffle.scheduleDay)?.label || ""
      } בשעה ${raffle.scheduleTime}`;

    case "monthly":
      return `${raffle.scheduleDate} לחודש בשעה ${raffle.scheduleTime}`;

    default:
      return raffle.scheduleTime;
  }
};

// פונקציית עזר להמרת תאריך לתזמון
export const dateToScheduleTime = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

// פונקציית עזר לבדיקת תקינות תאריך
export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
};

// פונקציית עזר להשוואת תאריכים (ללא שעה)
export const isSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

// המרת Timestamp לתאריך ISO
export const timestampToISOString = (timestamp: Timestamp): string => {
  return new Date(timestamp.seconds * 1000).toISOString().split("T")[0];
};
