import type { NewRaffle, RaffleTypeOption, DayOfWeek } from "@/types/raffle";

export const DEFAULT_NEW_RAFFLE: NewRaffle = {
  title: "",
  description: "",
  date: "",
  endDate: "",
  scheduleTime: "12:00",
  type: "oneTime",
  status: "upcoming",
  prizeCodes: [""],
  imageUrl: "",
  pointsCost: 0,
  maxTicketsPerUser: undefined,
  minPointsRequired: undefined,
  skipWeekend: false,
  scheduleDay: 1,
  scheduleDate: 1,
};

export const RAFFLE_TYPES: RaffleTypeOption[] = [
  { value: "oneTime", label: "חד פעמית" },
  { value: "daily", label: "יומית" },
  { value: "weekly", label: "שבועית" },
  { value: "monthly", label: "חודשית" },
];

export const DAYS_OF_WEEK: DayOfWeek[] = [
  { value: 1, label: "ראשון" },
  { value: 2, label: "שני" },
  { value: 3, label: "שלישי" },
  { value: 4, label: "רביעי" },
  { value: 5, label: "חמישי" },
  { value: 6, label: "שישי" },
  { value: 7, label: "שבת" },
];

export const STATUS_COLORS = {
  upcoming: "text-yellow-600",
  active: "text-green-600",
  completed: "text-blue-600",
} as const;

export const STATUS_TEXTS = {
  upcoming: "מתוכננת",
  active: "פעילה",
  completed: "הסתיימה",
} as const;
