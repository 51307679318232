import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "@/components/common/Dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/common/Label";
import { Progress } from "@/components/ui/progress";
import {
  Youtube,
  Share2,
  Check,
  Code,
  Loader2,
  XCircle,
  Upload,
} from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  MissionCompletionData,
  MissionDialogProps,
  isVideoMission,
  isUploadMission,
  isCodeMission,
  isSocialMission,
  getMissionRequirements,
} from "@/types/mission";

const MissionDialog: React.FC<MissionDialogProps> = ({
  isOpen,
  onClose,
  mission,
  onComplete,
  onImageSelect,
}) => {
  if (!mission) return null;

  const [error, setError] = useState<string | null>(null);
  const [code, setCode] = useState("");
  const [isWatching, setIsWatching] = useState(false);
  const [watchTime, setWatchTime] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, ] = useState(false);
  const [requirements, setRequirements] = useState<string[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (mission) {
      setRequirements(getMissionRequirements(mission));
    }
  }, [mission]);

  useEffect(() => {
    let timer: number;
    if (isWatching && isVideoMission(mission)) {
      timer = window.setInterval(() => {
        setWatchTime((prev) => prev + 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isWatching, mission]);

  const resetState = () => {
    setError(null);
    setCode("");
    setIsWatching(false);
    setWatchTime(0);
    setIsSubmitting(false);
    setImageFile(null);
    setUploadProgress(0);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (isUploadMission(mission)) {
      if (
        mission.maxFileSize &&
        file.size > mission.maxFileSize * 1024 * 1024
      ) {
        setError(`גודל הקובץ חייב להיות קטן מ-${mission.maxFileSize}MB`);
        return;
      }

      if (
        mission.allowedTypes?.length &&
        !mission.allowedTypes.includes(file.type)
      ) {
        setError("סוג הקובץ אינו נתמך");
        return;
      }
    }

    setImageFile(file);
    onImageSelect(file);
    setError(null);
  };

  const handleComplete = async () => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      setError(null);

      const completionData: MissionCompletionData = {
        instructionsImageUrl: mission.instructionsImageUrl || "",
      };

      switch (mission.type) {
        case "video":
          if (watchTime < mission.minimumTimeSeconds) {
            setError(`יש לצפות לפחות ${mission.minimumTimeSeconds} שניות`);
            return;
          }
          completionData.watchTime = watchTime;
          break;

        case "code":
          if (!code.trim()) {
            setError("יש להזין קוד");
            return;
          }
          completionData.code = code;
          break;

        case "social":
          const text = encodeURIComponent(
            mission.sharingText || "בואו להרוויח פרסים!"
          );
          const url = encodeURIComponent(window.location.origin);
          window.open(`https://wa.me/?text=${text}%20${url}`, "_blank");
          completionData.content = "shared";
          break;

        case "upload":
          return;
      }

      await onComplete(mission, completionData);
      handleClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : "שגיאה בהשלמת המשימה");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderMissionContent = () => {
    if (isVideoMission(mission)) {
      return (
        <div className="space-y-4">
          <div className="aspect-video bg-black rounded-lg overflow-hidden relative">
            {isWatching ? (
              <>
                <iframe
                  src={mission.videoUrl}
                  className="w-full h-full"
                  allowFullScreen
                />
                <a
                  href={mission.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="absolute top-2 right-2 bg-white/90 hover:bg-white rounded-lg p-2 text-sm flex items-center gap-2 transition-colors duration-200"
                >
                  <Youtube className="w-4 h-4" />
                  <span className="hidden sm:inline">פתח ביוטיוב</span>
                </a>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center h-full bg-gray-100">
                <Youtube className="w-8 sm:w-12 h-8 sm:h-12 text-gray-400 mb-2" />
                <Button
                  onClick={() => {
                    setIsWatching(true);
                    window.open(
                      mission.videoUrl,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                  className="flex items-center gap-2 text-sm sm:text-base"
                >
                  <Youtube className="w-4 h-4" />
                  צפה בסרטון
                </Button>
              </div>
            )}
          </div>

          {mission.minimumTimeSeconds > 0 && (
            <div className="space-y-2">
              <div className="flex justify-between text-xs sm:text-sm">
                <span>זמן צפייה</span>
                <span>
                  {watchTime} / {mission.minimumTimeSeconds} שניות
                </span>
              </div>
              <Progress
                value={(watchTime / mission.minimumTimeSeconds) * 100}
              />
            </div>
          )}
        </div>
      );
    }

    if (isUploadMission(mission)) {
      return (
        <div className="space-y-4">
          <div className="border-2 border-dashed border-gray-200 rounded-lg p-2 sm:p-4">
            <Label className="block mb-2 text-sm sm:text-base">
              העלאת תמונה
            </Label>
            <Input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              disabled={isSubmitting || isUploading}
              className="text-sm sm:text-base"
            />

            {imageFile && (
              <div className="mt-2 relative">
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="תצוגה מקדימה"
                  className="max-h-32 sm:max-h-40 rounded-md"
                />
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute top-1 right-1"
                  onClick={() => {
                    setImageFile(null);
                    onImageSelect(null);
                  }}
                >
                  <XCircle className="h-4 sm:h-5 w-4 sm:w-5 text-red-500" />
                </Button>
              </div>
            )}

            {isUploading && (
              <Progress value={uploadProgress} className="w-full mt-2" />
            )}

            {mission.maxFileSize && (
              <p className="text-xs sm:text-sm text-gray-600 mt-2">
                גודל קובץ מקסימלי: {mission.maxFileSize}MB
              </p>
            )}
          </div>
        </div>
      );
    }

    if (isCodeMission(mission)) {
      return (
        <div className="space-y-2">
          <Label className="text-sm sm:text-base">הזן קוד:</Label>
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="הכנס את הקוד כאן..."
            className="text-sm sm:text-base"
            onKeyPress={(e) =>
              e.key === "Enter" && !isSubmitting && handleComplete()
            }
          />
          {mission.codeInstructions && (
            <p className="text-xs sm:text-sm text-gray-600 mt-1">
              {mission.codeInstructions}
            </p>
          )}
        </div>
      );
    }

    if (isSocialMission(mission)) {
      return (
        <div className="space-y-2">
          <p className="text-sm sm:text-base text-gray-700">
            {mission.sharingText || "שתף את המשימה"}
          </p>
        </div>
      );
    }

    return null;
  };

  const renderActionButton = () => {
    let buttonContent: { text: string; icon: JSX.Element; disabled: boolean } =
      {
        text: "השלם משימה",
        icon: <Check className="w-4 h-4 ml-2" />,
        disabled: isSubmitting,
      };

    if (isVideoMission(mission)) {
      buttonContent = {
        text: "סיים צפייה",
        icon: <Check className="w-4 h-4 ml-2" />,
        disabled:
          !isWatching || watchTime < mission.minimumTimeSeconds || isSubmitting,
      };
    } else if (isUploadMission(mission)) {
      buttonContent = {
        text: "בחר תמונה",
        icon: <Upload className="w-4 h-4 ml-2" />,
        disabled: isSubmitting,
      };
    } else if (isCodeMission(mission)) {
      buttonContent = {
        text: "שלח קוד",
        icon: <Code className="w-4 h-4 ml-2" />,
        disabled: !code.trim() || isSubmitting,
      };
    } else if (isSocialMission(mission)) {
      buttonContent = {
        text: "שתף",
        icon: <Share2 className="w-4 h-4 ml-2" />,
        disabled: isSubmitting,
      };
    }

    return (
      <Button
        onClick={handleComplete}
        disabled={buttonContent.disabled}
        className="text-sm sm:text-base"
      >
        {isSubmitting ? (
          <Loader2 className="w-4 h-4 ml-2 animate-spin" />
        ) : (
          buttonContent.icon
        )}
        {buttonContent.text}
      </Button>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[95vw] sm:w-[90vw] max-w-[500px] max-h-[90vh] sm:max-h-[85vh] overflow-y-auto bg-white p-4 sm:p-6 rounded-lg">
        <DialogHeader>
          <DialogTitle className="text-lg sm:text-xl font-bold">
            {mission.title}
          </DialogTitle>
        </DialogHeader>

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription className="text-sm">{error}</AlertDescription>
          </Alert>
        )}

        <div className="py-4 space-y-4">
          {mission.imageUrl && !isVideoMission(mission) && (
            <div className="mb-4">
              <img
                src={mission.imageUrl}
                alt="תמונה לדוגמא"
                className="rounded-lg max-h-36 sm:max-h-48 w-auto mx-auto"
              />
            </div>
          )}

          {renderMissionContent()}

          {requirements.length > 0 && (
            <div className="p-3 sm:p-4 bg-gray-50 rounded-lg">
              <h4 className="text-sm sm:text-base font-medium mb-2">
                דרישות המשימה:
              </h4>
              <ul className="list-disc list-inside space-y-1 text-xs sm:text-sm text-gray-600">
                {requirements.map((req, index) => (
                  <li key={index}>{req}</li>
                ))}
              </ul>
            </div>
          )}

          {mission.description && (
            <p className="text-sm sm:text-base text-gray-700">
              {mission.description}
            </p>
          )}
        </div>

        <DialogFooter className="gap-2 mt-4">
          <Button
            variant="outline"
            onClick={handleClose}
            disabled={isSubmitting || isUploading}
            className="text-sm sm:text-base"
          >
            ביטול
          </Button>
          {renderActionButton()}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MissionDialog;
