import { Timestamp } from "firebase/firestore";

// Basic Mission Types
export type MissionType = "upload" | "video" | "code" | "social";

// Mission Status
export type MissionStatus = "active" | "inactive";

export interface AdReward {
  enabled: boolean;
  pointsPerView: number;
  maxViewsPerDay: number;
  viewsToday?: number;
  lastViewedAt?: Timestamp | null;
}
export interface FormData {
  title: string;
  description: string;
  type: MissionType;
  points: number;
  status: MissionStatus;
  imageUrl: string;
  instructionsImageUrl: string;
  requirements: string;
  frequency: MissionFrequency;
  contentInstructions: string;
  maxFileSize: number;
  allowedTypes: string[];
  requiredScreenshots: number;
  videoUrl: string;
  minimumTimeSeconds: number;
  codeInstructions: string;
  validCodes: string[];
  maxAttempts: number;
  platform: string;
  sharingText: string;
  shareLink: string;
  adReward?: AdReward;
}

// Mission Frequency Types
export type MissionFrequency = "once" | "daily" | "weekly" | "monthly";
export interface MissionCompletionData {
  code?: string;
  url?: string;
  watchTime?: number;
  content?: string;
  imageUrl?: string;
  instructionsImageUrl: string;
  platform?: string;
  sharedUrl?: string;
  sharedText?: string;
}
export interface MissionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  mission: Mission;
  onComplete: (mission: Mission, data: MissionCompletionData) => void;
  onImageSelect: (file: File | null) => void; // עדכון הטיפוס כך שיקבל גם null
}

export interface MissionCompletionData {
  code?: string;
  url?: string;
  watchTime?: number;
  progress?: number;
  platform?: string;
  content?: string; // Added for social missions
  imageUrl?: string;
  sharedUrl?: string;
  sharedText?: string;
  additionalData?: Record<string, any>;
  validCodes?: string[];
  attemptsUsed?: number;
  timestamp?: Date;
}
// Constants
export const MISSION_FREQUENCIES: Record<MissionFrequency, string> = {
  once: "חד פעמי",
  daily: "יומי",
  weekly: "שבועי",
  monthly: "חודשי",
} as const;

export const MISSION_STATUSES: Record<MissionStatus, string> = {
  active: "פעיל",
  inactive: "לא פעיל",
} as const;
// Base Mission Interface
export interface BaseMission {
  id: string;
  title: string;
  description: string;
  type: MissionType;
  points: number;
  status: MissionStatus;
  frequency: MissionFrequency;
  imageUrl?: string;
  instructionsImageUrl?: string; // הוספת השדה
  requirements?: string;
  nextAvailableAt?: Timestamp;
  lastCompletedAt?: Timestamp;
  expiresAt?: Timestamp;
}
// Specific Mission Types
export interface VideoMission extends BaseMission {
  type: "video";
  videoUrl: string;
  minimumTimeSeconds: number;
  watchedTime?: number;
}

export interface UploadMission extends BaseMission {
  type: "upload";
  maxFileSize?: number;
  allowedTypes?: string[];
  requiredScreenshots?: number;
  contentInstructions?: string;
}

export interface CodeMission extends BaseMission {
  type: "code";
  codeInstructions: string;
  validCodes: string[];
  maxAttempts?: number;
  attemptsUsed?: number;
  adReward?: AdReward;
}

export interface SocialMission extends BaseMission {
  type: "social";
  platform: string;
  shareLink?: string;
  sharingText?: string;
}

// Union Type
export type Mission =
  | VideoMission
  | UploadMission
  | CodeMission
  | SocialMission;

// Form Data Interface
export interface MissionFormData extends BaseMission {
  // Video mission fields
  videoUrl?: string;
  minimumTimeSeconds?: number;
  watchedTime?: number;

  // Upload mission fields
  maxFileSize?: number;
  allowedTypes?: string[];
  requiredScreenshots?: number;
  contentInstructions?: string;

  // Code mission fields
  codeInstructions?: string;
  validCodes?: string[];
  maxAttempts?: number;
  attemptsUsed?: number;

  // Social mission fields
  platform?: string;
  shareLink?: string;
  sharingText?: string;
}

// Helper Functions
export const calculateNextAvailable = (
  frequency: MissionFrequency,
  lastCompletedAt: Date
): Date | null => {
  switch (frequency) {
    case "once":
      return null; // Once completed, never available again
    case "daily":
      const tomorrow = new Date(lastCompletedAt);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return tomorrow;
    case "weekly":
      const nextWeek = new Date(lastCompletedAt);
      nextWeek.setDate(nextWeek.getDate() + 7);
      nextWeek.setHours(0, 0, 0, 0);
      return nextWeek;
    case "monthly":
      const nextMonth = new Date(lastCompletedAt);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      nextMonth.setHours(0, 0, 0, 0);
      return nextMonth;
    default:
      return null;
  }
};

export const getFrequencyProgress = (
  frequency: MissionFrequency,
  nextAvailableAt: Timestamp
): number => {
  const now = new Date().getTime();
  const availableDate = nextAvailableAt.toDate().getTime();
  const diff = availableDate - now;

  let totalDuration: number;
  switch (frequency) {
    case "daily":
      totalDuration = 24 * 60 * 60 * 1000; // 24 hours
      break;
    case "weekly":
      totalDuration = 7 * 24 * 60 * 60 * 1000; // 7 days
      break;
    case "monthly":
      totalDuration = 30 * 24 * 60 * 60 * 1000; // 30 days
      break;
    default:
      return 0;
  }

  const progress = ((totalDuration - diff) / totalDuration) * 100;
  return Math.max(0, Math.min(100, progress));
};

export const getMissionRequirements = (mission: Mission): string[] => {
  const requirements: string[] = [];

  // Add frequency requirement
  if (mission.frequency) {
    requirements.push(`תדירות: ${MISSION_FREQUENCIES[mission.frequency]}`);
  }

  switch (mission.type) {
    case "video":
      requirements.push(`צפייה של ${mission.minimumTimeSeconds} שניות`);
      break;
    case "upload":
      if (mission.maxFileSize) {
        requirements.push(`גודל קובץ מקסימלי: ${mission.maxFileSize}MB`);
      }
      if (mission.requiredScreenshots) {
        requirements.push(`נדרש ${mission.requiredScreenshots} צילומי מסך`);
      }
      break;
    case "code":
      requirements.push(mission.codeInstructions);
      break;
    case "social":
      requirements.push(`שיתוף ב${mission.platform}`);
      break;
  }

  if (mission.requirements) {
    requirements.push(mission.requirements);
  }

  return requirements;
};

export const isMissionAvailable = (mission: Mission): boolean => {
  // בדיקת סטטוס - אם לא פעיל, המשימה לא זמינה
  if (mission.status === "inactive") {
    return false;
  }

  // בדיקת nextAvailableAt
  if (mission.nextAvailableAt) {
    const now = new Date();
    const nextAvailable = mission.nextAvailableAt.toDate();
    if (now < nextAvailable) {
      return false;
    }
  }

  // בדיקת תאריך תפוגה
  if (mission.expiresAt) {
    const now = new Date();
    const expiryDate = mission.expiresAt.toDate();
    if (now > expiryDate) {
      return false;
    }
  }

  // אם עברנו את כל הבדיקות והמשימה פעילה, היא זמינה
  return mission.status === "active";
};

// Type Guards
export const isVideoMission = (mission: Mission): mission is VideoMission =>
  mission.type === "video";

export const isUploadMission = (mission: Mission): mission is UploadMission =>
  mission.type === "upload";

export const isCodeMission = (mission: Mission): mission is CodeMission =>
  mission.type === "code";

export const isSocialMission = (mission: Mission): mission is SocialMission =>
  mission.type === "social";
