// src/pages/admin/raffles.tsx
import { useState, useEffect } from "react";
import { RaffleTable } from "@/components/admin/RaffleTable";
import { RaffleForm } from "@/components/admin/RaffleForm";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

import { db } from "@/lib/firebase";
import { Loader2 } from "lucide-react";
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import type { Raffle, NewRaffle, RaffleStatus } from "@/types/raffle";
import { DEFAULT_NEW_RAFFLE } from "@/constants/raffle";
import { useToast } from "@/components/common/Use-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/common/Dialog";

export default function AdminRaffles() {
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingRaffle, setEditingRaffle] = useState<Raffle | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { toast } = useToast();

  // שליפת רשימת ההגרלות
  useEffect(() => {
    const q = query(collection(db, "raffles"), orderBy("createdAt", "desc"));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const raffleData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Raffle[];
        setRaffles(raffleData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching raffles:", error);
        toast({
          title: "שגיאה בטעינת נתונים",
          description: "אירעה שגיאה בטעינת ההגרלות",
          variant: "destructive",
        });
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [toast]);

  // הוספת הגרלה חדשה
  const handleAddRaffle = async (data: NewRaffle) => {
    setSubmitting(true);
    try {
      const [hours, minutes] = data.scheduleTime.split(':');
      
      const startDate = new Date(data.date);
      startDate.setHours(parseInt(hours), parseInt(minutes), 0);
  
      const endDate = data.endDate ? new Date(data.endDate) : new Date(startDate);
      endDate.setHours(parseInt(hours), parseInt(minutes), 0);
  
      // בניית אובייקט הבסיס
      const baseRaffleData = {
        title: data.title,
        description: data.description,
        date: Timestamp.fromDate(startDate),
        endDate: Timestamp.fromDate(endDate),
        scheduleTime: data.scheduleTime,
        type: data.type,
        status: data.status,
        imageUrl: data.imageUrl || "",
        pointsCost: data.pointsCost || 0,
        createdAt: Timestamp.now(),
        totalParticipants: 0,
        participants: [],
        prizeCodes: data.prizeCodes.map((code) => ({
          id: crypto.randomUUID(),
          code: code.trim(),
          isRevealed: false,
        }))
      };
  
      // הוספת שדות אופציונליים רק אם יש להם ערך
      const optionalFields: Partial<Raffle> = {};
      
      if (data.scheduleDay) {
        optionalFields.scheduleDay = data.scheduleDay;
      }
      
      if (data.scheduleDate) {
        optionalFields.scheduleDate = data.scheduleDate;
      }
      
      if (typeof data.skipWeekend === 'boolean') {
        optionalFields.skipWeekend = data.skipWeekend;
      }
      
      if (typeof data.maxTicketsPerUser === 'number') {
        optionalFields.maxTicketsPerUser = data.maxTicketsPerUser;
      }
      
      if (typeof data.minPointsRequired === 'number') {
        optionalFields.minPointsRequired = data.minPointsRequired;
      }
  
      // מיזוג האובייקט הבסיסי עם השדות האופציונליים
      const raffleData = {
        ...baseRaffleData,
        ...optionalFields
      };
  
      await addDoc(collection(db, "raffles"), raffleData);
      setShowModal(false);
      toast({ title: "ההגרלה נוספה בהצלחה" });
    } catch (error) {
      console.error("Error adding raffle:", error);
      toast({
        title: "שגיאה בהוספת הגרלה",
        description: "אירעה שגיאה בהוספת ההגרלה",
        variant: "destructive",
      });
    } finally {
      setSubmitting(false);
    }
  };
  // עריכת הגרלה קיימת
  const handleEditRaffle = async (data: NewRaffle) => {
    if (!editingRaffle) return;

    setSubmitting(true);
    try {
      const raffleRef = doc(db, "raffles", editingRaffle.id);

      // בדיקת אילו שדות השתנו
      const updates: Partial<Raffle> = {};

      if (editingRaffle.title !== data.title) updates.title = data.title;
      if (editingRaffle.description !== data.description)
        updates.description = data.description;
      if (editingRaffle.pointsCost !== data.pointsCost)
        updates.pointsCost = data.pointsCost;
      if (editingRaffle.imageUrl !== data.imageUrl)
        updates.imageUrl = data.imageUrl;
      if (editingRaffle.type !== data.type) updates.type = data.type;
      if (editingRaffle.scheduleTime !== data.scheduleTime)
        updates.scheduleTime = data.scheduleTime;

      // עדכון קודי זכייה אם השתנו
      const currentCodes = editingRaffle.prizeCodes.map((pc) => pc.code);
      const newCodes = data.prizeCodes;

      if (JSON.stringify(currentCodes) !== JSON.stringify(newCodes)) {
        updates.prizeCodes = newCodes.map((code) => ({
          id: crypto.randomUUID(),
          code: code.trim(),
          isRevealed: false,
        }));
      }

      if (Object.keys(updates).length > 0) {
        await updateDoc(raffleRef, updates);
        toast({ title: "ההגרלה עודכנה בהצלחה" });
      }

      setShowModal(false);
      setEditingRaffle(null);
    } catch (error) {
      console.error("Error updating raffle:", error);
      toast({
        title: "שגיאה בעדכון הגרלה",
        description: "אירעה שגיאה בעדכון ההגרלה",
        variant: "destructive",
      });
    } finally {
      setSubmitting(false);
    }
  };

  // עדכון סטטוס הגרלה
  const handleStatusChange = async (id: string, status: RaffleStatus) => {
    try {
      const raffleRef = doc(db, "raffles", id);
      await updateDoc(raffleRef, { status });

      // אם ההגרלה מסתיימת, נאפס את המשתתפים להגרלות חוזרות
      if (status === "completed") {
        const raffle = raffles.find((r) => r.id === id);
        if (raffle && ["daily", "weekly", "monthly"].includes(raffle.type)) {
          await updateDoc(raffleRef, {
            participants: [],
            totalParticipants: 0,
            status: "upcoming",
          });
        }
      }

      toast({ title: "הסטטוס עודכן בהצלחה" });
    } catch (error) {
      console.error("Error updating status:", error);
      toast({
        title: "שגיאה בעדכון סטטוס",
        description: "אירעה שגיאה בעדכון הסטטוס",
        variant: "destructive",
      });
    }
  };
  const getInitialFormData = (raffle: Raffle | null): NewRaffle => {
    if (!raffle) return DEFAULT_NEW_RAFFLE;

    return {
      title: raffle.title,
      description: raffle.description,
      date: new Date(raffle.date.seconds * 1000).toISOString().split("T")[0],
      endDate: new Date(raffle.endDate.seconds * 1000)
        .toISOString()
        .split("T")[0],
      scheduleTime: raffle.scheduleTime,
      scheduleDay: raffle.scheduleDay,
      scheduleDate: raffle.scheduleDate,
      type: raffle.type,
      status: raffle.status,
      prizeCodes: raffle.prizeCodes.map((pc) => pc.code),
      imageUrl: raffle.imageUrl,
      pointsCost: raffle.pointsCost,
      maxTicketsPerUser: raffle.maxTicketsPerUser,
      minPointsRequired: raffle.minPointsRequired,
      skipWeekend: raffle.skipWeekend,
    };
  };
  // מחיקת הגרלה
  const handleDelete = async (id: string) => {
    if (!window.confirm("האם למחוק את ההגרלה?")) return;

    try {
      await deleteDoc(doc(db, "raffles", id));
      toast({ title: "ההגרלה נמחקה בהצלחה" });
    } catch (error) {
      console.error("Error deleting raffle:", error);
      toast({
        title: "שגיאה במחיקת הגרלה",
        description: "אירעה שגיאה במחיקת ההגרלה",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <h1 className="text-2xl font-bold">ניהול הגרלות</h1>
          <Button
            onClick={() => {
              setEditingRaffle(null);
              setShowModal(true);
            }}
          >
            הוסף הגרלה חדשה
          </Button>
        </CardHeader>

        <CardContent>
          <RaffleTable
            raffles={raffles}
            onStatusChange={handleStatusChange}
            onEdit={(raffle) => {
              setEditingRaffle(raffle);
              setShowModal(true);
            }}
            onDelete={handleDelete}
          />
        </CardContent>
      </Card>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              {editingRaffle ? "עריכת הגרלה" : "הוספת הגרלה חדשה"}
            </DialogTitle>
          </DialogHeader>

          <RaffleForm
            initialData={getInitialFormData(editingRaffle)}
            onSubmit={editingRaffle ? handleEditRaffle : handleAddRaffle}
            isLoading={submitting}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
