import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

// Layouts
import Layout from "@/components/layout/Layout";

// Pages
import Home from "@/pages/Home";
import RafflePage from "@/pages/RafflePage";
import MissionsPage from "@/pages/MissionsPage";
import ProfilePage from "@/pages/ProfilePage";
import LoginPage from "@/pages/LoginPage";
import RegisterPage from "@/pages/RegisterPage";
import AdminPage from "@/pages/AdminPage";
import AboutPage from "@/pages/AboutPage";
import NotFoundPage from "@/pages/NotFoundPage";

// Admin Pages
import AdminRaffles from "@/pages/admin/AdminRaffles";
import AdminMissions from "@/pages/admin/AdminMissions";
import AdminUsers from "@/pages/admin/AdminUsers";
import AdminCodes from "@/pages/admin/AdminCodes";
import AdminAnalytics from "@/pages/admin/AdminAnalytics";
import AdminAnnouncements from "@/pages/admin/AdminAnnouncements";
import ArticleDetailPage from "@/pages/articles/ArticleDetailPage";
import ArticlesCategoryPage from "@/pages/articles/ArticlesCategoryPage";
import ArticlesPage from "@/pages/articles/ArticlesPage";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requireAdmin = false,
}) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (requireAdmin && !currentUser.isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Home />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="about" element={<AboutPage />} />

        {/* Protected Routes */}
        <Route
          path="raffle/:id"
          element={
            <ProtectedRoute>
              <RafflePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="missions"
          element={
            <ProtectedRoute>
              <MissionsPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />

        {/* Admin Routes */}
        <Route
          path="admin"
          element={
            <ProtectedRoute requireAdmin>
              <AdminPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="raffles" replace />} />
          <Route path="raffles" element={<AdminRaffles />} />
          <Route path="missions" element={<AdminMissions />} />
          <Route path="users" element={<AdminUsers />} />
          <Route path="codes" element={<AdminCodes />} />
          <Route path="analytics" element={<AdminAnalytics />} />
          <Route path="announcements" element={<AdminAnnouncements />} />
        </Route>
        <Route path="articles">
          <Route index element={<ArticlesPage />} />
          <Route path=":category" element={<ArticlesCategoryPage />} />
          <Route path=":category/:id" element={<ArticleDetailPage />} />
        </Route>
        {/* 404 Page */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
