import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Timer, Users, Award, Calendar, Gift, Info } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/common/Use-toast";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Wheel } from "react-custom-roulette";
import { useAuth } from "@/contexts/AuthContext";
import {
  DialogFooter,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Dialog,
} from "@/components/common/Dialog";
import { Label } from "@/components/common/Label";
import { Input } from "@/components/ui/input";
import AutoRaffleDraw from "@/components/raffle/AutoRaffleDraw";
import CountdownTimer from "./RaffleDetailsPage";

interface Participant {
  id: string;
  username: string;
  entries: number;
  joinedAt: Timestamp;
  points: number;
}

interface RaffleData {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  endDate: Timestamp | string;
  raffleTime: string;
  pointsCost: number;
  scheduleTime?: string;
  participants: Participant[];
  totalParticipants: number;
  winner?: {
    userId: string;
    username: string;
    code: string;
  };
  status: "upcoming" | "active" | "completed";
  type: "oneTime" | "daily" | "weekly" | "monthly";
  prizeCodes: Array<{
    id: string;
    code: string;
    isRevealed: boolean;
  }>;
  createdAt: Timestamp;
  maxTicketsPerUser?: number;
  minPointsRequired?: number;
}

const getRaffleTypeText = (type: string) => {
  const types = {
    oneTime: "חד פעמית",
    daily: "יומית",
    weekly: "שבועית",
    monthly: "חודשית",
  };
  return types[type as keyof typeof types] || type;
};

const formatTime = (time: string) => {
  if (!time) return "00:00";
  const [hours, minutes] = time.split(":");
  return `${hours.padStart(2, "0")}:${minutes?.padStart(2, "0") || "00"}`;
};

const RafflePage = () => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const { toast } = useToast();
  const [raffle, setRaffle] = useState<RaffleData | null>(null);

  const [canParticipate, setCanParticipate] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [showBuyDialog, setShowBuyDialog] = useState(false);
  const [ticketsToBuy, setTicketsToBuy] = useState(1);
  const [userPoints, setUserPoints] = useState<number | null>(null);

  const wheelData = useMemo(() => {
    if (!raffle?.participants?.length) return [];

    return raffle.participants.map((participant) => ({
      option: participant.username,
      style: {
        backgroundColor: `hsl(${Math.random() * 360}, 70%, 50%)`,
        textColor: "white",
      },
    }));
  }, [raffle?.participants]);

  // Load user points
  useEffect(() => {
    if (currentUser?.id) {
      const loadUserPoints = async () => {
        const userDoc = await getDoc(doc(db, "users", currentUser.id));
        if (userDoc.exists()) {
          setUserPoints(userDoc.data().points || 0);
        }
      };
      loadUserPoints();
    }
  }, [currentUser]);

  // Raffle subscription
  useEffect(() => {
    if (!id) return;

    const unsubscribe = onSnapshot(doc(db, "raffles", id), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setRaffle({
          id: doc.id,
          ...data,
          participants: data.participants || [],
        } as RaffleData);
      }
    });

    return () => unsubscribe();
  }, [id]);

  // Timer effect

  const handleBuyTickets = async () => {
    if (!currentUser || !raffle || !userPoints) return;

    const totalCost = raffle.pointsCost * ticketsToBuy;

    try {
      // וידוא נקודות מינימום
      if (raffle.minPointsRequired && userPoints < raffle.minPointsRequired) {
        toast({
          title: "אין מספיק נקודות",
          description: `נדרשות לפחות ${raffle.minPointsRequired} נקודות כדי להשתתף`,
          variant: "destructive",
        });
        return;
      }

      // בדיקת עלות כוללת
      if (userPoints < totalCost) {
        toast({
          title: "אין מספיק נקודות",
          description: `נדרשות ${totalCost} נקודות לרכישת ${ticketsToBuy} כרטיסים`,
          variant: "destructive",
        });
        return;
      }

      const existingParticipant = raffle.participants.find(
        (p) => p.id === currentUser.id
      );
      const currentEntries = existingParticipant?.entries || 0;

      // בדיקת מגבלת כרטיסים
      if (
        raffle.maxTicketsPerUser &&
        currentEntries + ticketsToBuy > raffle.maxTicketsPerUser
      ) {
        toast({
          title: "חריגה ממגבלת הכרטיסים",
          description: `ניתן לרכוש עד ${
            raffle.maxTicketsPerUser - currentEntries
          } כרטיסים נוספים`,
          variant: "destructive",
        });
        return;
      }

      // עדכון נתוני המשתתף
      if (existingParticipant) {
        // עדכון משתתף קיים - לא משנים את totalParticipants
        const updatedParticipants = raffle.participants.map((p) =>
          p.id === currentUser.id
            ? { ...p, entries: p.entries + ticketsToBuy }
            : p
        );

        await updateDoc(doc(db, "raffles", raffle.id), {
          participants: updatedParticipants,
        });
      } else {
        // משתתף חדש - מוסיפים למספר המשתתפים הכולל
        const newParticipant: Participant = {
          id: currentUser.id,
          username: currentUser.username,
          entries: ticketsToBuy,
          joinedAt: Timestamp.now(),
          points: userPoints,
        };

        await updateDoc(doc(db, "raffles", raffle.id), {
          participants: [...raffle.participants, newParticipant],
          totalParticipants: (raffle.participants.length || 0) + 1, // מוסיפים 1 כי זה משתתף חדש
        });
      }

      // עדכון נקודות המשתמש
      const newPoints = userPoints - totalCost;
      await updateDoc(doc(db, "users", currentUser.id), {
        points: newPoints,
      });
      setUserPoints(newPoints);

      toast({
        title: "הרכישה בוצעה בהצלחה!",
        description: `נרכשו ${ticketsToBuy} כרטיסים`,
      });

      setShowBuyDialog(false);
      setTicketsToBuy(1);
    } catch (error) {
      console.error("Error buying tickets:", error);
      toast({
        title: "שגיאה ברכישת הכרטיסים",
        description: "אנא נסה שוב מאוחר יותר",
        variant: "destructive",
      });
    }
  };

  const calculateWinChance = (participantId: string) => {
    if (!raffle?.participants.length) return 0;

    const participant = raffle.participants.find((p) => p.id === participantId);
    if (!participant) return 0;

    const totalEntries = raffle.participants.reduce(
      (sum, p) => sum + p.entries,
      0
    );
    return ((participant.entries / totalEntries) * 100).toFixed(2);
  };
  const endDateTime = useMemo(() => {
    if (!raffle) return new Date();
    debugger;
    const endDate =
      raffle.endDate instanceof Timestamp
        ? raffle.endDate.toDate()
        : new Date(raffle.endDate);

    const [hours, minutes] = (raffle.scheduleTime || "00:00").split(":");
    const targetDate = new Date(endDate);
    targetDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    setCanParticipate(raffle.status === "active");

    return targetDate;
  }, [raffle]);
  const handleParticipateClick = () => {
    if (!currentUser) {
      toast({
        title: "נדרשת התחברות",
        description: "יש להתחבר כדי להשתתף בהגרלה",
        variant: "destructive",
      });
      return;
    }

    if (canParticipate) {
      setShowBuyDialog(true);
    }
  };

  if (!raffle) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-8">
            <Card>
              <CardHeader className="relative p-0">
                <img
                  src={raffle.imageUrl}
                  alt={raffle.title}
                  className="w-full h-[400px] object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 p-6">
                  <h1 className="text-3xl font-bold text-white mb-2">
                    {raffle.title}
                  </h1>
                  <p className="text-gray-200">{raffle.description}</p>
                  <div className="flex items-center mt-2 text-white/90">
                    <Calendar className="w-5 h-5 ml-2" />
                    <span>{getRaffleTypeText(raffle.type)}</span>
                    <span className="ml-2">
                      • שעת הגרלה: {formatTime(raffle.raffleTime)}
                    </span>
                  </div>
                </div>
              </CardHeader>

              <CardContent className="p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                    <div className="flex items-center p-4 bg-white rounded-lg shadow-sm">
                      <Timer className="w-8 h-8 sm:w-10 sm:h-10 text-blue-500 ml-2 sm:ml-4" />
                      <div className="flex-1">
                        <p className="text-xs sm:text-sm font-medium text-gray-500">
                          זמן נותר
                        </p>
                        <CountdownTimer
                          endDate={endDateTime}
                          status={raffle.status}
                          onTimeEnd={() => {
                            setCanParticipate(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center p-4 bg-white rounded-lg shadow-sm">
                    <Users className="w-10 h-10 text-green-500 ml-4" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        משתתפים
                      </p>
                      <p className="text-lg font-semibold">
                        {raffle.totalParticipants}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 bg-white rounded-lg shadow-sm">
                    <Award className="w-10 h-10 text-purple-500 ml-4" />
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        עלות כרטיס
                      </p>
                      <p className="text-lg font-semibold">
                        {raffle.pointsCost} נקודות
                      </p>
                    </div>
                  </div>
                </div>

                {/* Requirements Section */}
                <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                  <h3 className="font-semibold mb-2 flex items-center gap-2">
                    <Info className="w-5 h-5" />
                    תנאי השתתפות
                  </h3>
                  <ul className="space-y-2 text-sm text-gray-600">
                    {raffle.minPointsRequired && (
                      <li>• נדרשות לפחות {raffle.minPointsRequired} נקודות</li>
                    )}
                    {raffle.maxTicketsPerUser && (
                      <li>
                        • מקסימום {raffle.maxTicketsPerUser} כרטיסים למשתתף
                      </li>
                    )}
                    <li>• מחיר כרטיס: {raffle.pointsCost} נקודות</li>
                    <li>• נקודות שלך: {userPoints || 0}</li>
                  </ul>
                </div>

                <Button
                  size="lg"
                  className="w-full"
                  onClick={handleParticipateClick}
                  disabled={raffle.status !== "active"}
                >
                  {!currentUser
                    ? "יש להתחבר כדי להשתתף"
                    : raffle.status === "completed"
                    ? "ההגרלה הסתיימה"
                    : raffle.status === "active"
                    ? "השתתף בהגרלה"
                    : "ההגרלה טרם החלה"}
                </Button>
              </CardContent>
            </Card>

            {/* Participants List */}
            <Card>
              <CardHeader>
                <h2 className="text-xl font-bold flex items-center gap-2">
                  <Users className="w-5 h-5" />
                  משתתפים בהגרלה
                </h2>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-[300px] rounded-md border">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>משתתף</TableHead>
                        <TableHead>כרטיסים</TableHead>
                        <TableHead>סיכויי זכייה</TableHead>
                        <TableHead>מועד הצטרפות</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {raffle.participants?.length > 0 ? (
                        raffle.participants.map((participant) => (
                          <TableRow key={participant.id}>
                            <TableCell className="font-medium">
                              {participant.username}
                              {participant.id === currentUser?.id && (
                                <span className="text-xs text-blue-500 mr-2">
                                  (אתה)
                                </span>
                              )}
                            </TableCell>
                            <TableCell>{participant.entries}</TableCell>
                            <TableCell>
                              {calculateWinChance(participant.id)}%
                            </TableCell>
                            <TableCell>
                              {participant.joinedAt instanceof Timestamp
                                ? new Date(
                                    participant.joinedAt.seconds * 1000
                                  ).toLocaleDateString("he-IL", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "-"}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            className="text-center py-8 text-gray-500"
                          >
                            עדיין אין משתתפים בהגרלה זו
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </ScrollArea>
              </CardContent>
            </Card>
          </div>

          {/* Right Column - Wheel */}
          <Card className="flex flex-col items-center justify-center p-6">
            <AutoRaffleDraw
              raffle={raffle}
              setSpinning={setSpinning}
              setPrizeNumber={setPrizeNumber}
            />

            {raffle.status === "completed" && raffle.winner ? (
              <div className="text-center">
                <div className="relative mb-6">
                  <Gift className="w-16 h-16 mx-auto text-green-500" />
                  <div className="absolute -top-2 -right-2 w-4 h-4 bg-green-400 rounded-full animate-ping" />
                </div>
                <h2 className="text-2xl font-bold mb-3">🎉 הזוכה בהגרלה 🎉</h2>
                <p className="text-xl mb-3">{raffle.winner.username}</p>
                {currentUser?.id === raffle.winner.userId ? (
                  <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                    <p className="text-lg font-semibold">
                      קוד זכייה:{" "}
                      <span className="font-mono">{raffle.winner.code}</span>
                    </p>
                  </div>
                ) : (
                  <p className="text-gray-500">הקוד נשלח לזוכה</p>
                )}
              </div>
            ) : wheelData.length > 0 ? (
              <div className="relative w-full max-w-md mx-auto">
                <Wheel
                  mustStartSpinning={spinning}
                  prizeNumber={prizeNumber}
                  data={wheelData}
                  outerBorderWidth={3}
                  radiusLineWidth={2}
                  fontSize={16}
                  textDistance={60}
                  spinDuration={10}
                  onStopSpinning={() => setSpinning(false)}
                  disableInitialAnimation={true}
                />
              </div>
            ) : (
              <div className="text-center">
                <Users className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                <p className="text-lg text-gray-500">
                  עדיין אין משתתפים בהגרלה
                </p>
              </div>
            )}
          </Card>
        </div>

        {/* Buy Tickets Dialog */}
        <Dialog open={showBuyDialog} onOpenChange={setShowBuyDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>רכישת כרטיסים</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <div className="space-y-2">
                <Label>מספר כרטיסים</Label>
                <Input
                  type="number"
                  min="1"
                  max={
                    raffle.maxTicketsPerUser
                      ? raffle.maxTicketsPerUser -
                        (raffle.participants.find(
                          (p) => p.id === currentUser?.id
                        )?.entries || 0)
                      : undefined
                  }
                  value={ticketsToBuy}
                  onChange={(e) =>
                    setTicketsToBuy(Math.max(1, parseInt(e.target.value) || 1))
                  }
                />
              </div>
              <div className="text-sm space-y-2">
                <p>מחיר: {raffle.pointsCost * ticketsToBuy} נקודות</p>
                <p>נקודות זמינות: {userPoints || 0}</p>
                {raffle.maxTicketsPerUser && (
                  <p>
                    כרטיסים נותרים:{" "}
                    {raffle.maxTicketsPerUser -
                      (raffle.participants.find((p) => p.id === currentUser?.id)
                        ?.entries || 0)}
                  </p>
                )}
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setShowBuyDialog(false)}>
                ביטול
              </Button>
              <Button onClick={handleBuyTickets}>רכישה</Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default RafflePage;
