import React, { useState } from "react";
import { Eye, Calendar } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { articles } from "./articles/data/articlesData";
import ArticleCard from "@/components/articles/ArticleCard";

// טיפוס עבור מאמר
export interface Article {
  id: number;
  title: string;
  content: string;
  author: string;
  date: string;
  views: number;
  likes: number;
  comments: number;
  category: string;
}

// Props של הקומפוננטה
interface ArticlesSectionProps {
  maxArticles?: number;
  showViewMore?: boolean;
  showFeatured?: boolean;
  showFilters?: boolean;
  className?: string;
}

// הקומפוננטה הראשית
const ArticlesSection: React.FC<ArticlesSectionProps> = ({
  maxArticles = Infinity,
  showViewMore = false,
  showFeatured = false,
  showFilters = true,
  className = "",
}) => {
  const [sortType, setSortType] = useState<"date" | "views">("date");

  const sortedArticles = React.useMemo(() => {
    let sorted =
      sortType === "views"
        ? [...articles].sort((a, b) => b.views - a.views)
        : [...articles].sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
          );

    return sorted.slice(0, maxArticles);
  }, [sortType, maxArticles]);

  return (
    <section className={`mt-12 ${className}`}>
      {showFilters && (
        <div className="flex flex-col sm:flex-row justify-between items-center mb-8 glass p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4 sm:mb-0 text-white">
            מדריכים ומאמרים
          </h2>

          <div className="flex items-center space-x-4 space-x-reverse">
            <Select
              value={sortType}
              onValueChange={(value: "date" | "views") => setSortType(value)}
            >
              <SelectTrigger className="w-[180px] glass text-white border-white/20">
                <SelectValue placeholder="בחר מיון" />
              </SelectTrigger>
              <SelectContent className="glass border-white/20">
                <SelectItem
                  value="date"
                  className="text-white hover:bg-white/20"
                >
                  <div className="flex items-center">
                    <Calendar className="w-4 h-4 ml-2" />
                    מיון לפי תאריך
                  </div>
                </SelectItem>
                <SelectItem
                  value="views"
                  className="text-white hover:bg-white/20"
                >
                  <div className="flex items-center">
                    <Eye className="w-4 h-4 ml-2" />
                    מיון לפי צפיות
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      )}

      {showFeatured && sortedArticles.length > 0 && (
        <div className="mb-8">
          <ArticleCard article={sortedArticles[0]} />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {sortedArticles.map((article, index) => (
          <React.Fragment key={article.id}>
            <div className="transform hover:scale-105 transition-transform duration-300 hover:shadow-xl">
              <ArticleCard article={article} />
            </div>
            {(index + 1) % 3 === 0 && index !== sortedArticles.length - 1 && (
              <div className="col-span-full glass rounded-lg overflow-hidden">
                <div className="h-32 flex items-center justify-center text-white/60">
                  מקום לפרסומת
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      {sortedArticles.length === 0 && (
        <div className="text-center py-12 glass rounded-lg">
          <h2 className="text-xl text-white">אין מאמרים זמינים כרגע</h2>
          <p className="text-white/80 mt-2">נסה לבדוק שוב מאוחר יותר</p>
        </div>
      )}

      {showViewMore && articles.length > maxArticles && (
        <div className="text-center mt-8">
          <button className="glass px-6 py-2 rounded-lg text-white hover:bg-white/10 transition-colors">
            הצג עוד מאמרים
          </button>
        </div>
      )}
    </section>
  );
};

export default ArticlesSection;
