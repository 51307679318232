import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { articles } from "./data/articlesData";

const ArticlesPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500 py-8"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">
            מאמרים ומדריכים
          </h1>
          <p className="text-white/80">
            המדריכים והטיפים הטובים ביותר לפורטנייט
          </p>
        </div>

        {/* Categories */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-8">
          {["מדריכים", "טיפים", "חדשות", "סקירות"].map((category) => (
            <Link
              key={category}
              to={`/articles/${category}`}
              className="bg-white/10 backdrop-blur-sm rounded-lg p-4 text-center
                        hover:bg-white/20 transition-all duration-200"
            >
              <span className="text-white font-medium">{category}</span>
            </Link>
          ))}
        </div>

        {/* Articles Grid */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {articles.map((article) => (
            <motion.div
              key={article.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white/10 backdrop-blur-sm rounded-lg overflow-hidden
                         hover:bg-white/20 transition-all duration-200"
            >
              <Link to={`/articles/${article.category}/${article.id}`}>
                <div className="p-6">
                  <h2 className="text-xl font-bold text-white mb-2">
                    {article.title}
                  </h2>
                  <p className="text-white/70 line-clamp-2 mb-4">
                    {article.content.substring(0, 150)}...
                  </p>

                  <div className="flex items-center justify-between text-sm text-white/60">
                    <span>{article.author}</span>
                    <span>{article.date}</span>
                  </div>

                  <div className="mt-4 flex items-center gap-4 text-sm text-white/60">
                    <span>👁 {article.views}</span>
                    <span>❤️ {article.likes}</span>
                    <span>💬 {article.comments}</span>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ArticlesPage;
