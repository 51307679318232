import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import type { RaffleStatus } from "@/types/raffle";
import { STATUS_COLORS, STATUS_TEXTS } from "@/constants/raffle";

interface RaffleStatusSelectProps {
  status: RaffleStatus;
  onChange: (status: RaffleStatus) => void;
}

// Helper functions
const getStatusColor = (status: RaffleStatus): string => {
  return STATUS_COLORS[status] || "";
};

const getStatusText = (status: RaffleStatus): string => {
  return STATUS_TEXTS[status] || status;
};

export function RaffleStatusSelect({
  status,
  onChange,
}: RaffleStatusSelectProps) {
  return (
    <Select value={status} onValueChange={onChange}>
      <SelectTrigger className={getStatusColor(status)}>
        <SelectValue>{getStatusText(status)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="upcoming">מתוכננת</SelectItem>
        <SelectItem value="active">פעילה</SelectItem>
        <SelectItem value="completed">הסתיימה</SelectItem>
      </SelectContent>
    </Select>
  );
}
