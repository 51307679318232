import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Edit2, Trash2 } from 'lucide-react';
import { RaffleStatusSelect } from '../RaffleStatusSelect';
import { formatDate, formatScheduleInfo } from '@/utils/date';
import type { Raffle, RaffleStatus } from '@/types/raffle';
import { RAFFLE_TYPES } from "@/constants/raffle";

interface RaffleTableRowProps {
  raffle: Raffle;
  onStatusChange: (id: string, status: RaffleStatus) => Promise<void>;
  onEdit: (raffle: Raffle) => void;
  onDelete: (id: string) => Promise<void>;
}

export function RaffleTableRow({
  raffle,
  onStatusChange,
  onEdit,
  onDelete
}: RaffleTableRowProps) {
  return (
    <TableRow>
      <TableCell>{raffle.title}</TableCell>
      <TableCell>
        {RAFFLE_TYPES.find((t) => t.value === raffle.type)?.label}
      </TableCell>
      <TableCell>
        {raffle.type === "oneTime" ? (
          formatDate(raffle.date)
        ) : (
          <div>
            <div>{formatScheduleInfo(raffle)}</div>
            {raffle.skipWeekend && (
              <div className="text-sm text-gray-500">ללא שישי-שבת</div>
            )}
          </div>
        )}
      </TableCell>
      <TableCell>{raffle.pointsCost} נקודות</TableCell>
      <TableCell>
        <RaffleStatusSelect
          status={raffle.status}
          onChange={(status) => onStatusChange(raffle.id, status)}
        />
      </TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(raffle)}
            disabled={raffle.status === "completed"}
          >
            <Edit2 className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(raffle.id)}
            disabled={raffle.status === "completed"}
          >
            <Trash2 className="h-4 w-4 text-red-600" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
}
