import React, { useState, useEffect } from "react";

interface CountdownTimerProps {
  endDate: Date;
  status: string;
  onTimeEnd?: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  endDate,
  status,
  onTimeEnd,
}) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = endDate.getTime() - now;

      if (distance <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        onTimeEnd?.();
        return null;
      }

      return {
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      };
    };

    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      if (time === null) {
        clearInterval(timer);
      } else {
        setTimeLeft(time);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate, onTimeEnd]);

  if (status === "completed") {
    return <div className="text-center text-gray-600">ההגרלה הסתיימה</div>;
  }

  const TimeBox = ({ value, label }: { value: number; label: string }) => (
    <div className="text-center">
      <div className="bg-gray-100 rounded-lg p-2 font-bold min-w-[2.5rem]">
        {String(value).padStart(2, "0")}
      </div>
      <div className="text-gray-500 mt-1">{label}</div>
    </div>
  );

  return (
    <div className="flex flex-row-reverse justify-center gap-4 text-sm">
      <TimeBox value={timeLeft.days} label="ימים" />
      <TimeBox value={timeLeft.hours} label="שעות" />
      <TimeBox value={timeLeft.minutes} label="דקות" />
      <TimeBox value={timeLeft.seconds} label="שניות" />
    </div>
  );
};

export default CountdownTimer;
