import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import { articles } from './data/articlesData';

const ArticleDetailPage = () => {
  const { id, category } = useParams();
  const article = articles.find(a => a.id === Number(id));

  if (!article) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500 
                      flex items-center justify-center">
        <div className="text-white text-center">
          <h1 className="text-2xl font-bold mb-4">המאמר לא נמצא</h1>
          <Link to="/articles" className="text-white/80 hover:text-white">
            חזרה לכל המאמרים
          </Link>
        </div>
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-purple-500 via-purple-600 to-pink-500 py-8"
    >
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Navigation */}
        <div className="mb-8">
          <Link to={`/articles/${category}`} className="text-white/80 hover:text-white">
            ← חזרה ל{category}
          </Link>
        </div>

        {/* Article Content */}
        <motion.article
          initial={{ y: 20 }}
          animate={{ y: 0 }}
          className="bg-white/10 backdrop-blur-sm rounded-lg overflow-hidden p-8"
        >
          <header className="mb-8">
            <h1 className="text-3xl font-bold text-white mb-4">{article.title}</h1>
            <div className="flex items-center justify-between text-white/60">
              <div className="flex items-center gap-4">
                <span>{article.author}</span>
                <span>{article.date}</span>
              </div>
              <div className="flex items-center gap-4">
                <span>👁 {article.views}</span>
                <span>❤️ {article.likes}</span>
                <span>💬 {article.comments}</span>
              </div>
            </div>
          </header>

          <div className="prose prose-invert prose-lg max-w-none">
            {article.content.split('\n').map((paragraph, index) => (
              <p key={index} className="text-white/90 mb-4">
                {paragraph}
              </p>
            ))}
          </div>

          {/* Comments Section */}
          <div className="mt-12 pt-8 border-t border-white/10">
            <h2 className="text-2xl font-bold text-white mb-6">תגובות ({article.comments})</h2>
            {/* Add your comments component here */}
          </div>
        </motion.article>
      </div>
    </motion.div>
  );
};

export default ArticleDetailPage;
