import { Table, TableBody, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { RaffleTableRow } from './RaffleTableRow';
import type { Raffle, RaffleStatus } from '@/types/raffle';

interface RaffleTableProps {
  raffles: Raffle[];
  onStatusChange: (id: string, status: RaffleStatus) => Promise<void>;
  onEdit: (raffle: Raffle) => void;
  onDelete: (id: string) => Promise<void>;
}

export function RaffleTable({ raffles, onStatusChange, onEdit, onDelete }: RaffleTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="text-right">כותרת</TableHead>
          <TableHead className="text-right">סוג</TableHead>
          <TableHead className="text-right">מועד</TableHead>
          <TableHead className="text-right">נקודות להשתתפות</TableHead>
          <TableHead className="text-right">סטטוס</TableHead>
          <TableHead className="text-right">פעולות</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {raffles.map((raffle) => (
          <RaffleTableRow
            key={raffle.id}
            raffle={raffle}
            onStatusChange={onStatusChange}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </TableBody>
    </Table>
  );
}